<template>
    <div id="app">
        <div v-if="this.isLoggedIn" class="wrapper">
            <aside class="main-sidebar sidebar-dark-primary elevation-4">
                <div v-if="this.isAdmin && this.isProduction"
                     style="background-color: #ec3b3b;color: #fff;font-size: 11px;text-align: center;padding: 2px 0;"
                >
                    You are using PRODUCTION environment
                </div>
                <a class="brand-link" href="/">
                    <span class="brand-text font-weight-light">{{ appTitle }}</span>
                </a>
                <div class="sidebar">
                    <Menu></Menu>
                </div>
            </aside>

            <nav class="main-header navbar navbar-expand navbar-white navbar-light">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" data-widget="pushmenu" href="javascript:void(0)"><i class="fa fa-bars"></i></a>
                    </li>
                </ul>
                <div class="row">
                    <div class="">
                        <select v-if="this.isDebugEnabled" id="lang-select" v-model="$i18n.locale" class="form-control">
                            <option v-for="(lang, i) in this.getLangs" :key="`Lang${i}`" :value="lang">
                                {{ lang }}
                            </option>
                        </select>
                    </div>
                </div>

                <ul class="navbar-nav ml-auto">
                    <li class="nav-item" style="line-height: 37px;">
                        <i class="nav-icon fas fa-user-shield"></i>
                        {{ this.getUsername }} ({{ $t('you') }})
                    </li>
                </ul>
            </nav>

            <div class="content-wrapper"  v-if="this.isInited">
                <router-view :key="$route.fullPath"/>
            </div>

        </div>
        <div v-else class="wrapper">
            <router-view/>
        </div>

        <div v-if="this.isModalOpened" class="modal-backdrop fade show"></div>
    </div>
</template>

<script>
// @todo get rid of ridiculous fix for menu js init (the one of placing invisible component aside)
import Menu from './components/Menu';
import {mapGetters} from 'vuex';

export default {
    components: {
        Menu,
    },
    data() {
        return {
            appTitle: process.env.VUE_APP_NAME,
            appEnv: process.env.VUE_APP_ENV
        };
    },
    computed: {
        ...mapGetters([
            'getUsername',
            'isLoggedIn',
            'isInited',
            'isModalOpened',
            'isAdmin',
            'getLangs',
            'isDebugEnabled',
            'isShowDebugTools',
        ]),
        isProduction() {
            return this.appEnv === 'production'
        },
        appRef() {
            return process.env.VUE_APP_GIT_REF;
        },
    },
    methods: {
        toggleDebugTools() {
            this.$store.dispatch('toggleDebugTools');
        },
    },
    mounted() {
        document.body.classList.add('sidebar-mini', 'layout-fixed');
    },

};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="../static/adminLte/css/adminlte.min.css"></style>
<style src="../static/custom.css"></style>
