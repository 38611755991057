<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-plug"></i> {{ this.$route.name }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="form-group col-sm-2">
                        <input class="btn btn-primary" type="button" value="New operator" @click="showCreateForm">&nbsp;&nbsp;
                    </div>
                </div>

                <data-table-wrapper
                    ref="operatorsTable"
                    :columns="columns"
                    :filters="filters"
                    endpoint="/operators"
                    sort-column-default="id"
                    sort-order-default="asc"
                    v-on:edit-request="this.showEditForm"
                    v-on:delete-request="this.showDeleteForm"
                    :action-buttons="actionButtons"
                ></data-table-wrapper>
            </div>

            <edit-operator
                v-if="editOperator"
                :item="editOperator"
                :mode="editMode"
                v-on:close="this.hideEditForm"
                v-on:finish="this.loadData"
            ></edit-operator>

            <delete-operator
                v-if="deleteOperator"
                :operator="deleteOperator"
                v-on:close="this.hideDeleteForm"
                v-on:success="this.loadData"
            ></delete-operator>

        </section>
    </div>
</template>

<script>
import EditOperator from '../components/EditOperator';
import {mapGetters} from 'vuex';
import DeleteOperator from '../components/DeleteOperator';
import DataTableWrapper from '../components/DataTableWrapper';

export default {
    components: {
        EditOperator,
        DeleteOperator,
        DataTableWrapper,
    },
    data() {
        return {
            editMode: null,
            editOperator: null,
            deleteOperator: null,
            columns: [
                {
                    field: 'name',
                    title: 'Name',
                    sorting: true,
                    filterEnabled: true,
                },
                {
                    field: 'partnerName',
                    title: 'Partner name',
                },
            ],
            filters: [
                {
                    field: 'name',
                    placeholder: 'Enter name',
                    label: 'Name',
                },
                {
                    field: 'partnerName',
                    placeholder: 'Enter partner name',
                    label: 'Partner name',
                },
            ],
            actionButtons: [
                (row) =>
                <span class="btn btn-outline-secondary btn-xs" onClick={() => {
                    console.log(row);
                    this.showCopyForm(row)
                }}><i
                    class="fas fa-copy"></i>&nbsp;Copy</span>,
                () => <span>&nbsp;</span>
            ]
        };
    },
    computed: {
        ...mapGetters([
            'isShowDebugTools',
        ]),
    },
    methods: {
        showCreateForm() {
            let data = {
                id: '',
                name: '',
                disabledSlotsIds: [],
                slotsOrder: [],
                usePostMessageAuth: true
            };

            this.editMode = 'create';
            this.editOperator = data;
        },
        showEditForm(data) {
            this.editMode = 'edit';
            this.editOperator = {...data};
        },
        showCopyForm(data) {
            this.editMode = 'create';
            this.editOperator = {...data, id: '', secret: null, token: null};
        },
        hideEditForm() {
            this.editMode = null;
            this.editOperator = null;
        },
        showDeleteForm(data) {
            this.deleteOperator = {...data};
        },
        hideDeleteForm() {
            this.deleteOperator = null;
        },
        loadData() {
            this.$refs.operatorsTable.loadData();
        },
    },
    mounted() {
        this.$store.dispatch('loadReelsPresets')
    },
};
</script>
