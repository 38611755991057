<template>
  <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <form @submit="submit" autocomplete="test-edit">
          <div class="modal-header">
            <h5 class="modal-title">{{ tournament.id ? 'Edit' : 'Create' }} tournament</h5>
            <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                    @click="$emit('close')">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-12">
                <div class="form-group">
                  <label for="name">Name</label>
                  <input v-model="tournament.name"
                         class="form-control"
                         name="name"
                         id="name"
                         placeholder="Enter name"
                         required
                         type="text"
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label>Partner</label>
                  <multiselect
                    v-model="tournament.partner"
                    :deselect-label="$t('Press enter to remove')"
                    :multiple="false"
                    :options="this.getPartners"
                    :placeholder="$t('Select partner')"
                    :select-label="$t('Press enter to select')"
                    :selected-label="$t('Selected')"
                    @select="tournament.operators = []"
                    label="name"
                    track-by="id"
                  ></multiselect>
                </div>
              </div>

              <div class="col-sm-6" v-if="tournament.partner">
                <div class="form-group">
                  <label>Operators</label>
                  <multiselect
                    v-model="tournament.operators"
                    :deselect-label="$t('Press enter to remove')"
                    :multiple="true"
                    :options="this.getOperatorsOptions"
                    :placeholder="$t('All operators')"
                    :select-label="$t('Press enter to select')"
                    :selected-label="$t('Selected')"
                    label="name"
                    track-by="id"
                  ></multiselect>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="startDate">Start Date</label>
                  <date-picker id="startDate"
                               v-model="tournament.startDate"
                               input-class="form-control"
                               type="datetime"
                               valueType="format"
                  ></date-picker>
                </div>
              </div>

              <div class="col-sm-6">
                <div class="form-group">
                  <label for="endDate">End Date</label>
                  <date-picker id="endDate"
                               v-model="tournament.endDate"
                               input-class="form-control"
                               type="datetime"
                               valueType="format"
                  ></date-picker>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-6">
                <div class="form-group">
                  <label for="rateToPoints">
                    Rate To Points <small>(1 point =
                    {{ this.tournament.rateToOnePoint ? this.tournament.rateToOnePoint : 'X' }} EUR)</small>
                  </label>
                  <input v-model="tournament.rateToOnePoint"
                         class="form-control"
                         name="rateToPoints"
                         id="rateToPoints"
                         placeholder="1"
                         required
                         type="text"
                  >
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-sm-12">
                <label>Prizes ({{ prizesSum }} EUR)</label>
              </div>
            </div>
            <div class="row" v-for="(prize, index) in this.tournament.prizes" :key="index">
              <div class="col-sm-2">
                <div class="form-group">
                  <label class="form-control border-0">
                    #{{ index + 1 }}
                  </label>
                </div>
              </div>
              <div class="col-sm-8">
                <div class="form-group">
                  <input class="form-control" placeholder="Enter sum (EUR)" required v-model="prize.prizeSum">
                </div>
              </div>
              <div class="col-sm-2">
                <button class="btn btn-danger" type="button" @click="removePrize(index)">
                  <i class="fa fa-times"></i>
                </button>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-2">
                <button class="btn btn-default" @click="addPrize" type="button">
                  <i class="fa fa-plus"></i> Add Prize
                </button>
              </div>
            </div>

          </div>
          <div class="modal-footer justify-content-between">
            <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>
            <button class="btn btn-primary" type="submit">Submit</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<style scoped>
#modal-default {
  display: block;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import {formatDatetime} from '../helpers/easytableCellFormats';
import {mapGetters} from 'vuex';

export default {
  props: ['item'],
  components: {
    Multiselect,
    DatePicker
  },
  computed: {
    ...mapGetters([
      'getOperators',
      'getPartners',
      'getPartner',
      'getOperator'
    ]),
    prizesSum() {
      let sum = 0;
      this.tournament.prizes.forEach((prize) => {
        let value = parseFloat(prize.prizeSum);
        if (!value) {
          return;
        }
        sum += value;
      });
      return sum;
    },
    tournamentFormData() {
      return {
        name: this.tournament.name,
        startDate: this.tournament.startDate,
        endDate: this.tournament.endDate,
        partnerId: this.tournament.partner.id,
        operatorIds: this.tournament.operators.map((operator) => operator.id),
        rateToPoints: (1 / this.tournament.rateToOnePoint).toString(),
        prizes: this.tournament.prizes.map((el, index) => {
          return {
            place: index + 1,
            prizeSum: el.prizeSum,
            currency: 'EUR'
          }
        }),
      }
    },
    getOperatorsOptions() {
      return this.getOperators.filter((operator) => {
        return operator.partnerId === this.tournament.partner.id;
      });
    },
  },
  methods: {
    removePrize(index) {
      this.tournament.prizes.splice(index, 1);
    },
    addPrize() {
      this.tournament.prizes.push({
        prizeSum: null
      })
    },
    submit(e) {
      e.preventDefault();

      this.$http({
        url: this.tournament.id ? '/tournaments/' + this.tournament.id : '/tournaments',
        data: {
          ...this.tournamentFormData
        },
        method: this.tournament.id ? 'PUT' : 'POST',
      }).then(() => {
        this.$emit('close');
        this.$emit('finish');
      }).catch((err) => {
        console.error(err);
      });
    }
  },
  mounted() {
    this.$store.dispatch('openModal');

    this.tournament.partner = this.item.partnerId ? this.getPartner(this.item.partnerId) : null;
    this.tournament.startDate = formatDatetime(this.item.startDate);
    this.tournament.endDate = formatDatetime(this.item.endDate);
    this.tournament.operators = this.item.operatorIds.map((id) => this.getOperator(id))
    this.tournament.rateToOnePoint = this.item.rateToPoints ? 1 / this.item.rateToPoints : null;
    this.tournament.prizes = [...this.item.prizes]
      .sort((a, b) => a.place > b.place ? 1 : -1)
      .map((prize) => {
        return {prizeSum: prize.prizeSum}
      });

  },
  beforeDestroy() {
    this.$store.dispatch('hideModal');
  },
  data() {
    return {
      tournament: {
        id: null,
        name: "",
        startDate: null,
        endDate: null,
        partner: null,
        operators: [],
        rateToOnePoint: '',
        prizes: [],
        ...this.item
      },
    };
  },
};
</script>
