import Vue from 'vue';
import VueRouter from 'vue-router';
import Axios from 'axios';
import App from './App.vue';
import router from './router';
import store from './store';
import {VeIcon, VeLoading, VePagination, VeTable} from 'vue-easytable';
import dotenv from 'dotenv';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
import VueI18n from 'vue-i18n';
import messages from './i18n';
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue';
// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css';
// plugins css
import 'vue-easytable/libs/theme-default/index.css';
import 'vue2-datepicker/index.css';
import 'admin-lte';
import VueToastr from 'vue-toastr';
import {Service} from 'axios-middleware';
import ExportManager from "./services/exportManager";

if (process.env.VUE_APP_SENTRY_DSN) {
    Sentry.init({
        Vue,
        dsn: process.env.VUE_APP_SENTRY_DSN,
        integrations: [
            new Integrations.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracingOrigins: ['localhost', /^\//],
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);

// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

dotenv.config();

Vue.use(VueRouter);
Vue.use(VeTable);
Vue.use(VePagination);
Vue.use(VeLoading);
Vue.use(VeIcon);
Vue.use(VueToastr, {});
Vue.use(VueI18n);

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

const token = localStorage.getItem('token');

Vue.config.productionTip = false;
Vue.config.analyticsURL = process.env.VUE_APP_ANALYTICS_BASE_URL;
Axios.defaults.baseURL = process.env.VUE_APP_BACK_OFFICE_BASE_URL;
console.log(Axios.defaults.baseURL);


let auth = {
    isRefreshing: false,
    refreshingCall: undefined,
}

function refreshToken(cb) {
    if (auth.isRefreshing) {
        return auth.refreshingCall.then(cb);
    }
    auth.isRefreshing = true;
    auth.refreshingCall = Axios.post('/token/refresh', {refreshToken: localStorage.getItem('refreshToken')})
        .then(tokenRefreshResponse => {
            localStorage.setItem('token', tokenRefreshResponse.data.token);
            localStorage.setItem('refreshToken', tokenRefreshResponse.data.refreshToken);
            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + tokenRefreshResponse.data.token;
            auth.isRefreshing = false;
            auth.refreshingCall = undefined;
            return Promise.resolve(tokenRefreshResponse.data.token);
        }).then(cb);
    return auth.refreshingCall;
}

Axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const request = error.config;

        if (
            request.url === '/token/refresh' &&
            request.method === 'post' &&
            error &&
            error.response &&
            error.response.status === 401
        ) {
            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('username');
            delete Axios.defaults.headers.common['Authorization'];
            window.location.reload();
        }

        if (error &&
            error.response &&
            error.response.status === 401) {

            return refreshToken(() => {
                error.config.headers['Authorization'] = 'Bearer ' + localStorage.getItem('token');
                return Axios.request(error.config);
            });
        }

        throw error;
    }
);

Vue.prototype.$http = Axios;
const service = new Service(Axios);

service.register({
    onRequest(config) {
        if (router.currentRoute.name === 'Login') {
            return config;
        }

        localStorage.setItem('referer', router.currentRoute.fullPath);

        let lastActiveAt = localStorage.getItem('lastActiveAt');

        if (lastActiveAt) {
            let idleSeconds = (Date.now() - Number.parseInt(lastActiveAt)) / 1000;

            let maxIdleSeconds = 20 * 60;

            if (process.env.VUE_APP_IDLE_SECONDS) {
                maxIdleSeconds = Number.parseInt(process.env.VUE_APP_IDLE_SECONDS);
            }

            if (idleSeconds > maxIdleSeconds) {
                localStorage.removeItem('lastActiveAt');
                localStorage.removeItem('token');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('username');
                delete Axios.defaults.headers.common['Authorization'];
                window.location.reload();
            }
        }

        return config;
    },
    onSync(promise) {
        return promise;
    },
    onResponse(response) {
        localStorage.setItem('lastActiveAt', Date.now().toString());
        return response;
    },
});

if (token) {
    Vue.prototype.$http.defaults.headers.common['Authorization'] = 'Bearer ' + token;
}

if (process.env.VUE_APP_DOCUMENT_TITLE) {
    document.title = process.env.VUE_APP_DOCUMENT_TITLE;
}

Vue.prototype.$veLoading = VeLoading;

const storeInit = store.dispatch('loadApplicationData')
router.beforeEach((to, from, next) => {
    storeInit
        .then(function () {
            if (to.matched.some(record => record.meta.requiresAuth)) {
                if (store.getters.isLoggedIn && to.meta.roles.indexOf(store.getters.getUserRole) !== -1) {
                    next();
                    return;
                }
                next('/login');
            } else {
                next();
            }
        })
        .catch()
});

let app = new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
    mounted() {
        this.$toastr.defaultPosition = 'toast-top-right';
    },
    watch: {
        '$i18n.locale'(newLocale) {
            localStorage.setItem('locale', newLocale);
        },
    },
}).$mount('#app');

const exportManager = new ExportManager(Axios, Vue.config.analyticsURL, app.$bvToast, app.$createElement);
Vue.prototype.$exportService = exportManager;
exportManager.checkExports();


if (localStorage.getItem('locale')) {
    i18n.locale = localStorage.getItem('locale');
}
