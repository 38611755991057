<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
              <div class="row col-lg-12 mb-3">
                <div class="col-sm-2">
                  <input class="btn btn-primary" type="button" value="New tournament" @click="showEditTournament">
                </div>
              </div>

                <data-table-wrapper
                    ref="dataTable"
                    :columns="columns"
                    :filters="filters"
                    :filter-defaults="filterDefaults"
                    :cell-style-option="cellStyleOption"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    endpoint="/tournaments"
                    sort-column-default="startDate"
                    sort-order-default="DESC"
                >
                </data-table-wrapper>
            </div>

          <edit-tournament
            v-if="tournamentDTO"
            :item="tournamentDTO"
            v-on:close="this.hideEditTournament"
            v-on:finish="() => { this.onFinishEdit(); }"
          ></edit-tournament>
        </section>
    </div>
</template>

<script>
import * as dateUtils from '../helpers/dateUtils';
import DataTableWrapper from '../components/DataTableWrapper';
import EditTournament from '../components/EditTournament';

import {mapGetters} from "vuex";

export default {
    components: {
        DataTableWrapper,
        EditTournament
    },
    data() {
        let self = this;
        return {
            tournamentDTO: null,
            cellStyleOption: {
            },
            filters: [
                {
                    field: 'fromDate',
                    placeholder: 'From Date',
                    label: 'From Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'toDate',
                    placeholder: 'To Date',
                    label: 'To Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'partnerIds',
                    placeholder: 'Select partners',
                    label: 'Partners',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (partners) => { return partners ? partners.map(partner => partner.id) :[]},
                    getOptions: () => this.getPartners,
                    trackBy: 'id'
                },
                {
                    field: 'operatorIds',
                    placeholder: 'Select operators',
                    label: 'Operators',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (operators) => { return operators ? operators.map(operator => operator.id) : [] },
                    getOptions: () => this.getOperatorsOptions,
                    trackBy: 'id'
                },
                {
                    field: 'name',
                    placeholder: 'Name',
                    label: 'Name',
                },
            ],
            columns: [
                {
                  field: 'name',
                  title: 'Name',
                  sorting: false,
                },
                {
                    field: 'startDate',
                    title: 'Start Date',
                    sorting: true,
                    isDate: true
                },
                {
                  field: 'endDate',
                  title: 'End Date',
                  sorting: false,
                  isDate: true
                },
                {
                    field: 'partnerId',
                    title: 'Partner',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getPartner(row.partnerId.toString()).name;
                    },
                },
                {
                    field: 'operatorIds',
                    title: 'Operators',
                    sorting: false,
                    callback: ({row}) => {
                        if (row.operatorIds.length === 0) {
                            return 'all';
                        }

                        return row.operatorIds.map((operatorId) => {
                          let name = self.getOperator(operatorId.toString()).name;
                          return (
                            <span class="multiselect__tag badge mr-1"><span>{ name }</span> </span>

                          )
                        });
                    },
                },
                {
                    field: 'rateToPoints',
                    title: 'Rate To Points',
                    sorting: false,
                    callback: ({row}) => {
                      return (
                        <span>1 point = {1 / row.rateToPoints } EUR</span>
                      )
                    },
                },
                  {
                    field: 'prizes',
                    title: 'Prizes count',
                    sorting: false,
                    callback: ({row}) => {
                      return row.prizes.length;
                    },
                  },
                {
                    field: 'actions',
                    title: 'Actions',
                    sorting: false,
                    callback: ({row}) => {
                        let editButton =
                            <span class="btn btn-primary btn-xs" onClick={() => this.showEditTournament(row)}><i
                                class="fas fa-edit"></i>&nbsp;Edit</span>;

                        let showParticipants =
                            <router-link to={{ name: 'tournamentsParticipants', params: {id: row.id}}} class="btn btn-info btn-xs">
                                <i class="fas fa-eye"></i>
                            </router-link>;

                        return <div class="right">{editButton} {showParticipants}</div>;
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'getPartners',
            'getPartner',
            'getOperators',
            'getOperator',
            'getCurrency',
            'isOperator',
        ]),
        getOperatorsOptions() {
            return this.getOperators.filter((operator) => {
                let selectedPartnerIds = this.$refs.dataTable.getFilterValue('partnerIds');
                if (selectedPartnerIds.length === 0) {
                    return true;
                }

                if (!operator.partnerId) {
                    return false;
                }

                return selectedPartnerIds.includes(operator.partnerId);
            });
        },
        filterDefaults() {
            return {
                fromDate: dateUtils.getTodayDate()
            }
        }
    },
    methods: {
      hideEditTournament() {
        this.tournamentDTO = null;
      },
      showEditTournament(data) {
        this.tournamentDTO = data
      },
      onFinishEdit() {
        this.$refs.dataTable.loadData();
      },
    },
    mounted() {
    },
};
</script>
