import Moment from 'moment';

export function formatDatetime(value, format = 'YYYY-MM-DD HH:mm:ss') {
    if (!value) {
        return ''
    }

    if (value.length === 0) {
        return '';
    }

    return Moment.utc(value).format(format);
}

export function easytableCellFormatNumber({row, column}) {
    const number = row[column.field] ?? 0;
    return formatNumber(number);
}

export function formatNumber(number) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export function formatNumberToFixed(number) {
    let num = Number.parseFloat(number);
    number = num.toFixed(2);
    return formatNumber(number);
}
