export default class ExportManager {
    constructor(axios, apiUrl, $bvToast, $createElement) {
        this.apiUrl = apiUrl;
        this.axios = axios;
        this.$bvToast = $bvToast;
        this.inProgressIds = localStorage.getItem('export_in_progress_ids') || [];
        if (!Array.isArray(this.inProgressIds)) {
            this.inProgressIds = [];
        }
        this.$createElement = $createElement
    }

    doExport(type, filters) {
        return this.axios({
            url: this.apiUrl + '/export/' + type,
            method: 'POST',
            data: filters,
        }).then(resp => {
            this.$bvToast.toast(`You will be notified on export complete (${resp.data.filename})`, {
                title: 'Export started',
                autoHideDelay: 5000,
                appendToast: true,
                solid: true
            });
            this._addInProgressId(resp.data.id);
            setTimeout(() => {
                this.checkExports();
            }, 2000);
        }).catch((err) => {
            console.log(err);
        }).finally(() => {

        });
    }

    _addInProgressId(id) {
        this.inProgressIds.push(id);
        localStorage.setItem('export_in_progress_ids', this.inProgressIds);
    }

    checkExports() {
        if (this.inProgressIds.length === 0) {
            return;
        }

        this.axios({
            url: this.apiUrl + '/export',
            method: 'GET',
            params: {ids: this.inProgressIds, limit: 500}
        }).then(resp => {
            let tasksById = {};
            for (let i in resp.data.items) {
                tasksById[parseInt(resp.data.items[i].id)] = resp.data.items[i];
            }

            let newIds = [];
            let completedTasks = [];
            for (let i in this.inProgressIds) {
                let id = parseInt(this.inProgressIds[i]);
                let task = tasksById[id] || null;
                if (!task) {
                    continue;
                }
                if (task.status === 2 || task.status === 3) {
                    completedTasks.push(task);
                    continue;
                }
                newIds.push(task.id);
            }

            this.inProgressIds = newIds;
            localStorage.setItem('export_in_progress_ids', this.inProgressIds);

            if (completedTasks.length > 0) {

                let els = [];
                for (let i in completedTasks) {
                    let task = completedTasks[i];
                    if (task.status === 2) {
                        els.push(this.$createElement('p', {}, [
                            task.filename + ' ',
                            this.$createElement('a', {domProps: {href: task.link, target: '_blank'}}, ['download'])
                        ]));
                    } else {
                        els.push(this.$createElement('p', {}, [
                            'Export failed: ' + task.filename,
                        ]));
                    }
                }

                const vNodesMsg = this.$createElement(
                    'div',
                    { class: [] },
                    els
                )

                this.$bvToast.toast([vNodesMsg], {
                    title: 'Export completed',
                    autoHideDelay: 5000,
                    appendToast: true,
                    solid: true
                });
            }

            setTimeout(() => {
                this.checkExports();
            }, 2000);

        }).catch((err) => {
            console.log(err);
        }).finally(() => {

        });
    }
}