<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-users-cog"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="col-sm-2">
                        <input class="btn btn-primary" type="button" value="New user" @click="showEditUser">
                    </div>
                </div>


                <data-table-wrapper
                    ref="usersTable"
                    :filters="filters"
                    :columns="columns"
                    endpoint="/users"
                    sort-column-default="id"
                    sort-order-default="asc"
                    v-on:edit-request="this.showEditUser"
                    v-on:delete-request="this.showDeleteUser"
                ></data-table-wrapper>
            </div>

            <edit-user
                v-if="editUser"
                :item="editUser"
                v-on:close="this.hideEditUser"
                v-on:finish="() => { this.onFinishEdit(); }"
            ></edit-user>

            <delete-user
                v-if="deleteUser"
                :item="deleteUser"
                v-on:close="this.hideDeleteUser"
                v-on:success="this.loadUsers"
            ></delete-user>

        </section>
    </div>
</template>

<script>
import EditUser from '../components/EditUser';
import DeleteUser from '../components/DeleteUser';
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from 'vuex';

export default {
    components: {
        EditUser,
        DeleteUser,
        DataTableWrapper,
    },
    data() {
        return {
            filters: [],
            columns: [
                {
                    field: 'username',
                    title: 'User name',
                    sorting: true,
                },
                {
                    field: 'email',
                    title: 'E-mail',
                },
                {
                    field: 'role',
                    title: 'Role',
                    callback: ({row}) => {
                        return row.role.id;
                    },
                },
                {
                    field: 'operatorsIds',
                    title: 'Operators',
                    callback: ({row}) => {
                        return row.operators.map(operator => operator.name).join(' ');
                    },
                },
            ],

            editUser: null,
            deleteUser: null,
        };
    },
    computed: {
        ...mapGetters([
            'getUsersRoles',
            'getCurrentUser',
            'getOperators',
            'getPartners',
            'getOperator'
        ]),
    },
    methods: {
        showEditUser(data) {
            if (data === null) {
                data = {
                    id: null,
                    username: '',
                    email: '',
                    password: '',
                    role: '',
                    is2faEnabled: '',
                    operators: [],
                    tables: [],
                };
            } else {
                // spread (workaround for clone, or main list will be edited realtime)
                data = {...data};
            }

            this.editUser = data;
        },
        hideEditUser() {
            this.editUser = null;
        },
        showDeleteUser(data) {
            this.deleteUser = data;
        },
        hideDeleteUser() {
            this.deleteUser = null;
        },
        onFinishEdit() {
            this.$store.dispatch('loadCurrentUser');
            this.loadUsers();
        },
        loadUsers() {
            this.$refs.usersTable.loadData();
        },
    },
    mounted() {
    },
};
</script>
