import Moment from 'moment';

export function formatNumber(number) {
    const parts = number.toString().split('.');
    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    return parts.join('.');
}

export function bytesToSize(bytes) {
    let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];

    if (bytes === 0) {
        return '0';
    }

    let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));

    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
}

// @todo move to date utils
export function secondsBetweenDates(from, to) {
    return Math.floor((Math.abs(to - from)) / 1000);
}

export function secondsToHuman(seconds) {
    let levels = [
        [Math.floor(seconds / 31536000), 'years'],
        [Math.floor((seconds % 31536000) / 86400), 'days'],
        [Math.floor(((seconds % 31536000) % 86400) / 3600), 'hours'],
        [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutes'],
        [(((seconds % 31536000) % 86400) % 3600) % 60, 'seconds'],
    ];
    let result = '';

    for (let i = 0, max = levels.length; i < max; i++) {
        if (levels[i][0] === 0) continue;
        result += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? levels[i][1].substr(0, levels[i][1].length - 1) : levels[i][1]);
    }

    return result.trim();
}

export function getTableDisplayName({customName, name, id}) {
    return (customName || name) + ` (${id})`;
}

export function formatNumberToFixed(number, fractionDigits = 2) {
    let num = Number.parseFloat(number);
    number = num.toFixed(fractionDigits);
    return formatNumber(number);
}

export function formatDatetime(value) {
    if (!value) {
        return '';
    }

    if (typeof value.length !== 'undefined' && value.length === 0) {
        return '';
    }

    return Moment.utc(value).format('YYYY-MM-DD HH:mm:ss');
}
