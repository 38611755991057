<template>
    <li class="nav-item nav-item-light" :class="{'menu-open': opened}">
        <a href="#" class="nav-link" @click.prevent="toggle(node)">
            <i v-if="node.children && node.children.length" class="fas fa-angle-right"></i>
            <strong v-if="this.selectedId === node.id && this.groupId === selectedGroupId"> {{ node.title }}</strong>
            <span v-else> {{ node.title }}</span>

            <div v-if="!node.children || node.children.length === 0" data-toggle="buttons" class="btn-group btn-group-toggle" style="float:right">
                <button :id="'remove-' + groupId + '_' + node.id"
                        @click.stop
                        class="ml-2 btn btn-outline-danger btn-xs btn-control"
                >
                    <i class="fa fa-times"></i>
                </button>
                <b-popover
                           boundary="window"
                           placement="right"
                           :target="'remove-' + groupId + '_' + node.id"
                           title="Delete translation ID"
                           @show="hidePopovers"
                >
                    <div class="d-flex justify-content-end m-0 form-group">
                        <button type="button" @click="hidePopovers" class="btn btn-light btn-sm">Cancel</button>
                        <button type="button" @click="removeNode(node, groupId)" class="ml-2 btn btn-danger btn-sm">Delete</button>
                    </div>
                </b-popover>
            </div>
        </a>
        <ul v-if="node.children && node.children.length" class="nav nav-sidebar nav-treeview">
            <ul-node v-for="child in node.children" :group-id="groupId" :selected-group-id="selectedGroupId" :key="child.id" :selected-id="selectedId" :node="child" @remove="removeNode" @toggle="toggleChild"></ul-node>
        </ul>
    </li>
</template>

<script>
export default {
    name: "ul-node",
    props: {
        node: Object,
        selectedId: null,
        groupId: null,
        selectedGroupId: null
    },
    methods: {
        removeNode(node, groupId) {
            this.$emit('remove', node, groupId)
        },
        hidePopovers() {
            this.$root.$emit('bv::hide::popover');
        },
        toggle(node) {
            if (!node.children || node.children.length === 0) {
                this.opened = true;
            } else {
                this.opened = !this.opened;
            }

            this.$emit('toggle', node, this.opened, this.groupId)
        },
        toggleChild(node, opened, groupId) {
            this.$emit('toggle', node, opened, groupId)
        }
    },
    data() {
        return {opened: false}
    }
};
</script>