<template>
    <div>
        <b-modal
            ref="modal-container"
            v-model="modalShow"
            size="lg"
            title="Languages"
            :static="true"
            :lazy="true"
            hide-footer
        >
            <div class="modal-body">
                <div class="list-group list-group-flush">
                    <div class="list-group-item">
                        <div class="row">
                            <div class="col-md-1">Show</div>
                            <div class="col-md-3">Name</div>
                            <div class="col-md-2">Code</div>
                            <div class="col-md-2"></div>
                            <div v-if="group !== null" class="col-md-4"><strong>{{ group.name }}</strong> stats</div>
                        </div>
                    </div>

                    <div v-for="language in sortedLanguages" :key="language.id" class="list-group-item">
                        <form class="">
                            <div class="align-items-center row">
                                <div class="col-md-1">
                                    <button @click="toggleLanguageVisibility(language)"
                                            :disabled="isDisabled(language.id)" type="button"
                                            class="btn btn-light btn-sm">
                                        <i v-if="isUpdateInProgress(language)" class="fa fa-spinner fa-spin"></i>
                                        <i v-else-if="isHidden(language)" class="fa fa-ban"></i>
                                        <i v-else class="fa fa-check"></i>
                                    </button>
                                </div>
                                <div class="col-md-3">
                                    <input v-model="language.name" :readonly="isDisabled(language.id)" name="name"
                                           type="text" class="form-control form-control-sm">
                                </div>
                                <div class="col-md-2">
                                    <input v-model="language.id" :readonly="isDisabled(language.id)" name="id"
                                           type="text" class="form-control form-control-sm">
                                </div>
                                <div class="col-md-2">
                                    <div class="d-flex">
                                        <button @click="saveLanguage(language)" :disabled="isDisabled(language.id)"
                                                type="button" class="ml-2 btn btn-success btn-sm">
                                            <i class="fa fa-save"></i>
                                        </button>
                                        <div class="control-target">

                                            <button :id="'remove-language-btn-' + language.id"
                                                    :disabled="isDisabled(language.id)" type="button"
                                                    class="ml-2 btn btn-danger btn-sm">
                                                <i class="fa fa-times"></i>
                                            </button>
                                            <b-popover :ref="'removeLanguagePopover' +  language.id" placement="right"
                                                       boundary="window"
                                                       :target="'remove-language-btn-' + language.id"
                                                       :title="'Delete language?'"
                                            >
                                                <div class="d-flex justify-content-end m-0 form-group">
                                                    <button type="button"
                                                            @click="$refs['removeLanguagePopover' + language.id][0].$emit('close');"
                                                            class="btn btn-light btn-sm">Cancel
                                                    </button>
                                                    <button type="button" @click="removeLanguage(language)"
                                                            class="ml-2 btn btn-danger btn-sm">Delete
                                                    </button>
                                                </div>
                                            </b-popover>

                                        </div>
                                    </div>
                                </div>
                                <div v-if="group !== null" class="col-md-4">
                                    <b-progress :max="stats.totalTerms">
                                        <b-progress-bar :value="stats.counts[language.id]"
                                                        :label="`${((stats.counts[language.id] / stats.totalTerms) * 100).toFixed(0)}%`"></b-progress-bar>
                                    </b-progress>
                                </div>
                            </div>
                            <div class="d-flex invalid-feedback row">
                                <div class="col-md-1"></div>
                                <div class="col"></div>
                            </div>
                        </form>
                    </div>

                    <div class="list-group-item">
                        <form class="">
                            <div class="align-items-center row">
                                <div class="col-md-1"></div>
                                <div class="col-md-3">
                                    <input v-model="createLanguageForm.name" name="name" type="text"
                                           class="form-control form-control-sm">
                                </div>
                                <div class="col-md-2">
                                    <input v-model="createLanguageForm.id" name="id" type="text"
                                           class="form-control form-control-sm">
                                </div>
                                <div class="col-md-3">
                                    <div class="d-flex">
                                        <button @click="createLanguage()" type="button"
                                                class="ml-2 btn btn-success btn-sm">
                                            <i class="fa fa-save"></i>
                                        </button>
                                        <div class="control-target">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3"></div>
                            </div>
                            <div class="d-flex invalid-feedback row">
                                <div class="col-md-1"></div>
                                <div class="col"></div>
                            </div>
                        </form>
                    </div>


                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>


import Vue from "vue";
import {mapGetters} from "vuex";

export default {
    data() {
        return {
            group: null,
            inProgressUpdate: [],
            translations: [],
            terms: [],
            createLanguageForm: {name: '', id: '', enabled: false},
            modalShow: false,
            stats: {
                languages: {},
                totalTerms: 0,
                counts: {}
            }
        }
    },
    components: {},
    props: [
        'languages', 'disabledCodes', 'hiddenCodes'
    ],
    watch: {
        languages: function (newVal) {
            this.stats.languages = {};
            newVal.forEach((lang) => {
                this.stats.languages[lang.id] = Vue.util.extend({}, lang);
            })
        }
    },
    methods: {
        isUpdateInProgress(lang) {
            return this.inProgressUpdate.indexOf(lang.id) >= 0;
        },
        createLanguage() {
            this
                .$http({
                    url: '/localization/languages',
                    method: 'POST',
                    data: {
                        name: this.createLanguageForm.name,
                        id: this.createLanguageForm.id,
                        enabled: this.createLanguageForm.enabled
                    }
                })
                .then((resp) => {
                    this.createLanguageForm.name = '';
                    this.createLanguageForm.id = '';
                    this.$emit('languageCreated', resp.data);
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        isHidden(lang) {
            return !lang.enabled;
        },
        toggleLanguageVisibility(lang) {
            lang.enabled = !lang.enabled;
            this.inProgressUpdate.push(lang.id);
            this.saveLanguage(lang).finally(() => {
                this.inProgressUpdate = this.inProgressUpdate.filter((id) => id !== lang.id);
            });
        },
        removeLanguage(lang) {
            this
                .$http({
                    url: '/localization/languages/' + lang.id,
                    method: 'DELETE',
                })
                .then(() => {
                    this.$refs['removeLanguagePopover' + lang.id][0].$emit('close');
                    this.$emit('languageRemoved', lang.id);
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        saveLanguage(lang) {
            return this
                .$http({
                    url: '/localization/languages/' + lang.id,
                    method: 'PUT',
                    data: {
                        id: lang.id,
                        name: lang.name,
                        enabled: lang.enabled
                    }
                })
                .then(() => {
                    this.$emit('languageChanged', lang);
                })
                .catch((err) => {
                    console.log(err);
                })

        },
        isDisabled(id) {
            return this.disabledCodes.indexOf(id) >= 0;
        },
        toggle() {
            this.modalShow = !this.modalShow;
        },
        show(terms, translations, group) {
            this.group = group
            this.terms = terms;
            this.translations = translations;
            this.stats.totalTerms = this.terms.length;
            this.stats.counts = [];
            this.translations.forEach((trans) => {
                if (!trans.text) {
                    return;
                }
                if (!this.stats.counts[trans.languageId]) {
                    this.stats.counts[trans.languageId] = 0;
                }
                this.stats.counts[trans.languageId]++;
            })

            this.modalShow = true;
        }
    },
    computed: {
        sortedLanguages: function () {
            return Object.values(this.stats.languages).sort((a, b) => {
                if (this.isDisabled(a.id) === this.isDisabled(b.id)) {
                    return a.name > b.name ? 1 : -1;
                }
                return this.isDisabled(a.id) > this.isDisabled(b.id) ? -1 : 1
            })
        },
        ...mapGetters([
            'getGame'
        ]),
    },
    mounted() {
    },
}
</script>
