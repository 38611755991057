<template>
    <nav class="mt-2">
        <ul id="sidebarMenu" ref="menu" class="nav nav-pills nav-sidebar nav-child-indent flex-column text-capitalize" data-accordion="false" data-widget="treeview" role="menu">

            <li v-for="item in filterMenuItems(menu)" :key="item.route" class="nav-item" :class="navItemClass(item)">
                <router-link v-if="item.type == 'item'" class="nav-link" :class="isActive(item)" :to="{name: item.route}">
                    <i class="nav-icon fas" :class="item.icon"></i>
                    <p>{{ $t(item.title) }}</p>
                </router-link>

                <a v-if="item.type == 'group'" href="#" class="nav-link">
                    <i class="nav-icon fas fa-th" :class="item.icon"></i>
                    <p>
                        {{ $t(item.title) }}
                        <i class="right fas fa-angle-left"></i>
                    </p>
                </a>
                <ul v-if="item.type == 'group'"  class="nav nav-sidebar nav-treeview">
                    <li v-for="child in filterMenuItems(item.items)" :key="child.route" class="nav-item">
                        <router-link v-if="child.type == 'item'" class="nav-link" :class="isActive(child)" :to="{name: child.route}">
                            <i class="nav-icon fas" :class="child.icon"></i>
                            <p>{{ $t(child.title) }}</p>
                        </router-link>
                    </li>
                </ul>
            </li>

            <li class="nav-item">
                <a class="nav-link" href="javascript:void(0)" @click="doLogout()">
                    <i class="far fa-sign-out-alt nav-icon"></i>
                    <p>{{ $t('Log out') }}</p>
                </a>
            </li>

        </ul>
    </nav>

</template>

<style scoped>

.nav-link.router-link-exact-active {
    background-color: rgba(255, 255, 255, .1);
    color: #fff;
}

</style>

<script>
import {mapGetters} from 'vuex';
import * as types from '../store/types';
import {ROLE_ADMIN, ROLE_OPERATOR, ROLE_REPORTS} from '../definitions/roles';

export default {
    computed: {
        ...mapGetters([
            'getUserRole',
            'adminAreaPages',
            'dashboardPages',
            'adminReportsPages',
        ]),
        menu() {
            return [
                {
                    type: 'item', route: 'dashboard', title: 'dashboard', icon: 'fa-tachometer-alt'
                },
                {
                    type: 'group', title: 'reports', icon: 'fa-th', items: [
                        {
                            type: 'item', route: 'transactions', title: 'transactions', icon: 'fa-list'
                        },
                        {
                            type: 'item', route: 'sessions', title: 'sessions', icon: 'fa-list'
                        },
                        {
                            type: 'item', route: 'ggr', title: 'GGR', icon: 'fa-list'
                        },
                        {
                            type: 'item', route: 'authReport', title: 'Auth Report', icon: 'fa-list'
                        },
                        {
                            type: 'item', route: 'gameActions', title: 'Game Actions', icon: 'fa-list'
                        }
                    ]
                },
                {
                    type: 'item', route: 'games', title: 'games', icon: 'fa-dice'
                },
                {
                  type: 'item', route: 'tournaments', title: 'tournaments', icon: 'fa-trophy'
                },
                {
                    type: 'item', route: 'currencies', title: 'currencies', icon: 'fa-dollar-sign'
                },
                {
                    type: 'item', route: 'partners', title: 'partners', icon: 'fa-handshake'
                },
                {
                    type: 'item', route: 'operators', title: 'operators', icon: 'fa-plug'
                },
                {
                    type: 'item', route: 'users', title: 'users', icon: 'fa-users'
                },
                {
                    type: 'item', route: 'excludedUsers', title: 'Excluded Users', icon: 'fa-users'
                },
                {
                    type: 'item', route: 'translations', title: 'translations', icon: 'fa-language'
                },
                {
                    type: 'item', route: 'export', title: 'export', icon: 'fa-cloud-download'
                },
                {
                    type: 'item', route: 'health-check', title: 'Health Check', icon: 'fa-heartbeat'
                },
            ]
        }
    },
    methods: {
        navItemClass: function (item) {
            if (item.type === 'item') {
                return '';
            }
            for (let index in item.items) {
                if (item.items[index].route === this.$route.name) {
                    return 'menu-open';
                }
            }
            return '';
        },
        filterMenuItems: function (items) {
            return items.filter(item => this.hasAccess(item));
        },
        doLogout: function() {
            this.$store.dispatch(types.logout);
            this.$router.go();
        },
        getClassForGroup(groupName) {
            if (this.$route && this.$route.meta && this.$route.meta.groupName === groupName) {
                return 'nav-item mt-2 menu-open';
            }

            return 'nav-item mt-2';
        },
        isActive(item) {
            return this.$route.name === item.route ? 'active' : ''
        },
        hasAccess(item) {
            const rolesList = {
                'dashboard': [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
                'transactions': [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
                'ggr': [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
                'authReport': [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
                'sessions': [ROLE_ADMIN],
                'gameActions': [ROLE_ADMIN],
                'games': [ROLE_ADMIN],
                'currencies': [ROLE_ADMIN],
                'partners': [ROLE_ADMIN],
                'operators': [ROLE_ADMIN],
                'users': [ROLE_ADMIN],
                'excludedUsers': [ROLE_ADMIN],
                'translations': [ROLE_ADMIN],
                'export': [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
                'tournaments': [ROLE_ADMIN],
                'health-check': [ROLE_ADMIN],
            };

            if (item.type === 'item') {
                return rolesList[item.route].indexOf(this.getUserRole) !== -1;
            }

            for (let index in item.items) {
                if (rolesList[item.items[index].route].indexOf(this.getUserRole) !== -1) {
                    return true;
                }
            }
            return false;
        },
    },
    mounted() {
    },
};
</script>
