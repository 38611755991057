import Vue from 'vue';
import VueRouter from 'vue-router';
import Login from '../views/Login.vue';
import Users from '../views/Users.vue';
import ExcludedUsers from "../views/ExcludedUsers";
import Games from '../views/Games';
import Currencies from '../views/Currencies.vue';
import Operators from '../views/Operators.vue';
import Partners from '../views/Partners.vue';
import Dashboard from '../views/Dashboard.vue';
import DashboardCharts from '../views/DashboardCharts.vue';
import Transactions from '../views/Transactions.vue';
import GGR from '../views/GGR.vue';
import AuthReport from '../views/AuthReport.vue';
import ExportTasks from '../views/ExportTasks.vue';
import Sessions from '../views/Sessions.vue';
import GameActions from '../views/GameActions.vue';
import Tournaments from '../views/Tournaments.vue'
import TournamentsParticipants from "../views/TournamentsParticipants.vue";
import Localization from "../views/Localization";
import HealthCheck from "../views/HealthCheck";
import {ROLE_ADMIN, ROLE_OPERATOR, ROLE_REPORTS} from "../definitions/roles";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'dashboard',
        component: Dashboard,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/export',
        name: 'export',
        component: ExportTasks,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/dashboard/charts/',
        name: 'dashboard.charts',
        component: DashboardCharts,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/transactions',
        name: 'transactions',
        component: Transactions,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/translations',
        name: 'translations',
        component: Localization,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN],
            groupName: 'management',
        },
    },
    {
        path: '/ggr',
        name: 'ggr',
        component: GGR,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/auth-report',
        name: 'authReport',
        component: AuthReport,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/sessions/:sessionKey/game-actions',
        name: 'sessionGameActions',
        component: GameActions,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/game-actions',
        name: 'gameActions',
        component: GameActions,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN],
            groupName: 'management',
        },
    },
    {
        path: '/sessions',
        name: 'sessions',
        component: Sessions,
        meta: {
            requiresAuth: true,
            roles: [ROLE_ADMIN, ROLE_REPORTS, ROLE_OPERATOR],
            groupName: 'management',
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
    },
    {
        path: '/users',
        name: 'users',
        component: Users,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
        path: '/excluded-users',
        name: 'excludedUsers',
        component: ExcludedUsers,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
        path: '/games',
        name: 'games',
        component: Games,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
        path: '/currencies',
        name: 'currencies',
        component: Currencies,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
        path: '/operators',
        name: 'operators',
        component: Operators,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
      path: '/tournaments',
      name: 'tournaments',
      component: Tournaments,
      meta: {
        roles: [ROLE_ADMIN],
        requiresAuth: true,
        groupName: 'management',
      },
    },
    {
        path: '/health-check',
        name: 'health-check',
        component: HealthCheck,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
        path: '/tournaments/:id/participants',
        name: 'tournamentsParticipants',
        component: TournamentsParticipants,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
    {
        path: '/partners',
        name: 'partners',
        component: Partners,
        meta: {
            roles: [ROLE_ADMIN],
            requiresAuth: true,
            groupName: 'management',
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
