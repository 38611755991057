<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Confirm delete</h5>
                    <button aria-label="Close" class="close" data-dismiss="modal" type="button" @click="$emit('close')">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body">
                    <form @submit="deleteUser">
                        <h6>Are you sure you want to delete user?</h6>
                        <br/>
                        <button class="btn btn-primary" type="submit">Delete</button>
                        <button class="btn" type="button" @click="$emit('close')">Cancel</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#modal-default {
    display: block;
}
</style>

<script>
export default {
    props: ['item'],
    computed: {},
    methods: {
        deleteUser(e) {
            e.preventDefault();

            this.$http({url: '/users/' + this.item.id, method: 'DELETE'}).then(() => {
                this.$emit('close');
                this.$emit('success');
            }).catch((err) => {
                console.error(err);
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
};
</script>
