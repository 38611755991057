<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">Participants</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> Participants
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">

                <data-table-wrapper
                    ref="dataTable"
                    :columns="columns"
                    :filters="filters"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    :endpoint="'/tournaments/' + this.$route.params.id + '/participants'"
                    sort-column-default="points"
                    sort-order-default="DESC"
                >
                </data-table-wrapper>
            </div>
        </section>
    </div>
</template>

<script>
import DataTableWrapper from '../components/DataTableWrapper';

import {mapGetters} from "vuex";
import * as formatUtils from "../helpers/formatUtils";

export default {
    components: {
        DataTableWrapper
    },
    data() {
        return {
            filters: [],
            columns: [
                {
                  field: 'place',
                  title: 'Place',
                  sorting: false,
                },
                {
                    field: 'operatorId',
                    title: 'Operator',
                    sorting: false,
                    callback: ({row}) => {
                        return this.getOperator(row.operatorId).name;
                    },
                },
                {
                    field: 'uid',
                    title: 'User ID',
                    sorting: false,
                },
                {
                    field: 'username',
                    title: 'Username',
                    sorting: false,
                },
                {
                    field: 'points',
                    title: 'Points',
                    sorting: false,
                },
                {
                    field: 'prize',
                    title: 'Prize',
                    sorting: false,
                    callback: ({row}) => {
                        return row.prize ? formatUtils.formatNumberToFixed(row.prize.prizeSum) + ' ' + row.prize.currency : '';
                    },
                },
            ]
        };
    },
    computed: {
        ...mapGetters([
            'getOperator'
        ]),
    },
    methods: {
    },
    mounted() {
    },
};
</script>
