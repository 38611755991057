<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form @submit="submit">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ this.isCreateMode ? 'Create' : 'Edit' }} currency</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Code</label>
                            <input v-model="currentCurrency.code"
                                   :readonly="!isCreateMode"
                                   autocomplete="off"
                                   class="form-control"
                                   name="code"
                                   placeholder="Enter code"
                                   required
                                   type="text"
                            >
                        </div>
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="currentCurrency.name"
                                   autocomplete="off"
                                   class="form-control"
                                   name="name"
                                   placeholder="Enter name"
                                   required
                                   type="text"
                            >
                        </div>
                        <div class="form-group row col-sm-3">
                            <label>Subunits count</label>
                            <input v-model="currentCurrency.subunits"
                                   autocomplete="off"
                                   class="form-control"
                                   name="subunits"
                                   placeholder=""
                                   required
                                   type="number" step="1"
                            >
                        </div>
                        <div class="form-group">
                            <label>Rate to EUR</label>
                            <input v-model="currentCurrency.rate"
                                   :readonly="!currentCurrency.isRatesUpdateManual"
                                   autocomplete="off"
                                   class="form-control"
                                   name="rate"
                                   placeholder="Enter rate"
                                   type="text"
                            >
                        </div>
                      <div class="form-check">
                        <input id="checkboxIsManual"
                               v-model="currentCurrency.isRatesUpdateManual"
                               autocomplete="off"
                               class="form-check-input"
                               name="is_manual"
                               type="checkbox"
                        >
                        <label class="form-check-label" for="checkboxIsManual">Is updated manual</label>
                      </div>
                        <div class="form-group">
                            <label>Base Currency</label>
                            <multiselect
                                v-model="currentCurrency.baseCode"
                                :deselect-label="''"
                                :multiple="false"
                                :options="getCurrencies"
                                :placeholder="$t('Base Currency')"
                                :select-label="''"
                                :selected-label="''"
                                label="name"
                                track-by="code"
                            ></multiselect>
                        </div>
                        <div class="form-group">
                            <label>Factor</label> <small v-if="this.rateWithFactor">rate: {{ this.rateWithFactor.toFixed(4) }}</small>
                            <input v-model="currentCurrency.factor"
                                   autocomplete="off"
                                   class="form-control"
                                   name="factor"
                                   placeholder="Enter factor"
                                   type="text"
                            >
                        </div>
                        <div class="form-check">
                            <input id="checkboxIsFiat"
                                   v-model="currentCurrency.isFiat"
                                   autocomplete="off"
                                   class="form-check-input"
                                   name="is_fiat"
                                   type="checkbox"
                            >
                            <label class="form-check-label" for="checkboxIsFiat">Is Fiat</label>
                        </div>
                        <div class="form-check">
                            <input id="checkboxIsCrypto"
                                   v-model="currentCurrency.isCrypto"
                                   autocomplete="off"
                                   class="form-check-input"
                                   name="is_crypto"
                                   type="checkbox"
                            >
                            <label class="form-check-label" for="checkboxIsCrypto">Is Crypto</label>
                        </div>


                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
#modal-default {
    display: block;
}
</style>

<script>
import {mapGetters} from "vuex";
import Multiselect from 'vue-multiselect';

const MODE_CREATE = 'create';

export default {
    props: [
        'currency',
        'mode',
    ],
    computed: {
        rateWithFactor() {
            if (!this.currentCurrency.baseCode) {
                return null;
            }
            if (!this.currentCurrency.factor || this.currentCurrency.factor == 1) {
                return null;
            }
            let factor = parseFloat(this.currentCurrency.factor);
            let baseRate = parseFloat(this.currentCurrency.baseCode.rate);
            console.log(this.currentCurrency.factor, this.currentCurrency.baseCode);
            return baseRate / factor;
        },
        isCreateMode() {
            return this.mode === MODE_CREATE;
        },
        isDebug() {
            return process.env.NODE_ENV === 'development';
        },
        ...mapGetters([
            'getCurrencies',
            'getCurrency'
        ]),
    },
    components: {
        Multiselect,
    },
    methods: {
        isEngOnly(text) {
            const disallowed = /[^a-zA-Z]/i;
            return !disallowed.test(text);
        },
        submit(e) {
            e.preventDefault();

            if (!this.isEngOnly(this.currentCurrency.name)) {
                this.$toastr.e('Use only english codes');
                return;
            }

            let url = null;
            let method = null;

            if (this.isCreateMode) {
                method = 'POST';
                url = '/currencies';
            } else {
                method = 'PUT';
                url = '/currencies/' + this.currentCurrency.code;
            }

            let subunits = parseInt(this.currentCurrency.subunits);
            let postData = {
                code: this.currentCurrency.code,
                name: this.currentCurrency.name,
                rate: this.currentCurrency.rate,
                factor: parseFloat(this.currentCurrency.factor),
                isFiat: this.currentCurrency.isFiat,
                isCrypto: this.currentCurrency.isCrypto,
                isRatesUpdateManual: this.currentCurrency.isRatesUpdateManual,
                baseCode: this.currentCurrency.baseCode ? this.currentCurrency.baseCode.code : null,
                subunits: subunits ? subunits : 0
            };


            this.$http({
                url: url,
                data: postData,
                method: method,
            }).then(() => {
                this.$emit('finish');
                this.$emit('close');
                this.$store.dispatch('loadCurrencies');
            }).catch((err) => {
                console.error(err);
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
        if (this.currency.baseCode) {
            this.currentCurrency.baseCode = this.getCurrency(this.currency.baseCode)
        }
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
    data() {
        return {
            currentCurrency: {...this.currency},
        };
    },
};

</script>
