<template>
    <div>
        <b-modal
            v-model="modalShow"
            size="lg"
            :title="'Export ' + exportTitle"
            :static="true"
            :lazy="true"
            hide-footer
        >
            <div class="modal-body">
                <form class="">
                    <div class="form-row">
                        <div class="form-group col">
                            <label class="form-label">Language</label>
                            <b-form-select v-model="code" :options="codeOptions"></b-form-select>
                        </div>
                        <div class="form-group col"><label class="form-label">Format</label>
                            <div class="pt-1">
                                <div class="form-check form-check-inline">
                                    <b-form-radio v-model="format" name="format" value="json">json</b-form-radio>
                                </div>
                                <div class="form-check form-check-inline">
                                    <b-form-radio v-model="format" name="format" value="csv">csv</b-form-radio>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col"><label class="form-label">Mode</label>
                            <div class="pt-1">
                                <div class="form-check form-check-inline">
                                    <b-form-radio v-model="mode" name="mode" value="group">group</b-form-radio>
                                </div>
                                <div v-if="groupId !== 0" class="form-check form-check-inline">
                                    <b-form-radio v-model="mode" name="mode" value="full">full</b-form-radio>
                                </div>

                            </div>
                        </div>
                        <div class="form-group col"></div>
                        <div class="d-flex align-items-end justify-content-end form-group col-sm-2">
                            <button type="button" @click="exportTranslation" class="btn btn-success btn-sm">Export
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
    data() {
        return {
            modalShow: false,
            format: 'json',
            code: 'en',
            groupId: null,
            mode: 'group',
        }
    },
    components: {},
    props: [
        'languages'
    ],
    watch: {},
    methods: {
        exportTranslation() {

            this
                .$http({
                    url: this.mode === 'full'
                        ? '/localization/translations/games/' + this.groupId + '/' + this.code + '.' + this.format
                        : '/localization/translations/groups/' + this.groupId + '/' + this.code + '.' + this.format,
                    method: 'GET',
                    responseType: 'blob',
                })
                .then((response) => {
                    console.log(response);
                    var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                    var fileLink = document.createElement('a');

                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', response.headers['content-disposition'].split('filename=')[1]);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                    this.modalShow = false;
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        show(groupId) {
            this.groupId = groupId;
            this.mode = 'group';
            this.format = 'json';
            this.code = 'en';
            this.modalShow = true;
        },
    },
    computed: {
        ...mapGetters([
            'getGame'
        ]),
        exportTitle() {
            if (this.groupId === null) {
                return '';
            }
            if (this.groupId === 0) {
                return 'All Games';
            }
            return this.getGame(this.groupId).name;
        },
        codeOptions() {
            let options = this.languages
                .map((item) => {
                    return {value: item.id, text: item.name + ' (' + item.id + ')'}
                })
                .sort((a, b) => a.text > b.text ? 1 : -1);
            options.unshift({value: 'all', text: 'All'});

            return options;
        }
    },
    mounted() {
    },
}
</script>
