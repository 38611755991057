let en = {
    'Apply': 'Apply',
    'Clear': 'Clear',
    'From date': 'From date',
    'To date': 'To date',
    'Total': 'Total',
    'Main': 'Main',
    'Spain': 'Spain',
    'Dashboard / Total': 'Dashboard / Total',
    'Dashboard / Main': 'Dashboard / Main',
    'Dashboard / Spain': 'Dashboard / Spain',
    'Select operator': 'Select operator',
    'Select currency': 'Select currency',
    'Select partner': 'Select partner',
    'Press enter to select': 'Press enter to select',
    'Press enter to remove': 'Press enter to remove',
    'Current rate': 'Current rate',
    'Export CSV': 'Export CSV',
    'Home': 'Home',
    'Currencies': 'Currencies',
    'Tables': 'Tables',
    'Operators': 'Operators',
    'Partners': 'Partners',
    'Users': 'Users',
    'Management': 'Management',
    'Players': 'Players',
    'GGR': 'GGR',
    'Rounds': 'Rounds',
    'History': 'History',
    'Transactions': 'Transactions',
    'Log out': 'Log out',
    'MANAGEMENT': 'MANAGEMENT',
    'REPORTS': 'REPORTS',
    'SERVERS': 'SERVERS',
    'Wins': 'Wins',
    'Bets placed': 'Bets placed',
    'Bets': 'Bets',
    'Desktop': 'Desktop',
    'Mobile': 'Mobile',
    'Other': 'Other',
    'Loading': 'Loading',
    'Calculating rows': 'Calculating rows',
    'Waiting for worker': 'Waiting for worker',
    'Progress': 'Progress',
    'Writing to file': 'Writing to file',
    'Finished': 'Finished',
    'Download': 'Download',
    'Downloading': 'Downloading',
    'Server ID': 'Server ID',
    'Round ID': 'Round ID',
    'Partner': 'Partner',
    'Operator': 'Operator',
    'Brand': 'Brand',
    'User ID': 'User ID',
    'User': 'User',
    'Game Type': 'Game Type',
    'Table ID': 'Table ID',
    'Transaction type': 'Transaction type',
    'Amount': 'Amount',
    'Currency': 'Currency',
    'Balance': 'Balance',
    'Credit Sum': 'Credit Sum',
    'Debit Sum': 'Debit Sum',
    'Transaction ID': 'Transaction ID',
    'Details': 'Details',
    'Error': 'Error',
    'Retry': 'Retry',
    'Date': 'Date',
    'IP': 'IP',
    'Device type': 'Device type',
    'User Agent': 'User Agent',
    'credit': 'credit',
    'debit': 'debit',
    'GameType': 'GameType',
    'Bet Amount': 'Bet Amount',
    'Win Amount': 'Win Amount',
    'Rate EUR': 'Rate EUR',
    'Bet Amount EUR': 'Bet Amount EUR',
    'Win Amount EUR': 'Win Amount EUR',
    'Status': 'Status',
    'Date Open': 'Date Open',
    'Date Close': 'Date Close',
    'Date open': 'Date open',
    'Date close': 'Date close',
    'Result': 'Result',
    'Is X2': 'Is X2',
    'Game type': 'Game type',
    'Bet': 'Bet',
    'Win': 'Win',
    'Bet EUR': 'Bet EUR',
    'Win EUR': 'Win EUR',
    'GGR EUR': 'GGR EUR',
    'Rounds count': 'Rounds count',
    'Total bet': 'Total bet',
    'Total win': 'Total win',
    'Total bet EUR': 'Total bet EUR',
    'Total win EUR': 'Total win EUR',
    'opened': 'opened',
    'closed success': 'closed success',
    'cancelled / autocancelled': 'cancelled / autocancelled',
    'Selected': 'Selected',
    'Provision': 'Provision',
    '7 Mojos': '7 Mojos',
    'TVBet': 'TVBet',
    'Select game type': 'Select game type',
    'Select table': 'Select table',
    'Dashboard / Provision': 'Dashboard / Provision',
    'Dashboard / 7 Mojos': 'Dashboard / 7 Mojos',
    'Dashboard / TVBet': 'Dashboard / TVBet',
    'RTP': 'RTP',
    'Reset': 'Reset',
    'Cancel': 'Cancel',
    'Search': 'Search',
    'Select manual date': 'Select manual date',
    'Welcome': 'Welcome',
    'Today': 'Today',
    'Yesterday': 'Yesterday',
    'Previous month': 'Previous month',
    'Current month': 'Current month',
    'Regions': 'Regions',
    'Select country': 'Select country',
    'Roulette Stats': 'Roulette Stats',
    'Roulette stats': 'Roulette stats',
    'Select server': 'Select server',
    'Portomaso': 'Portomaso',
    'Black': 'Black',
    'Dashboard / Black': 'Dashboard / Black',
    'Dashboard / Portomaso': 'Dashboard / Portomaso',
    'you': 'you',
    'Select transaction types': 'Select transaction types',
    'Select game types': 'Select game types',
    'Select tables': 'Select tables',
    'Select partners': 'Select partners',
    'Select operators': 'Select operators',
    'Advanced': 'Advanced',
    'Select status': 'Select status',
    'Operation Day Summary': 'Operation Day Summary',
    'action.400': 'Unknown action',
    'action.1': 'Session created',
    'action.2': 'Spin',
    'action.3': 'Respin',
    'action.4': 'Freespin',
    'action.5': 'Start bonus',
    'action.6': 'Buy bonus',
    'action.7': 'Avalanche',
    'action.8': 'Jackpot',
    'action.9': 'End bonus',
    'action.10': 'Session restored',
    'action.11': 'State request',
    'action.12': 'Session auto closed',
    'action.13': 'Game loaded',
};

let es = {};
let ja = {};
let ru = {};
let debug = {};

for (const [key, value] of Object.entries(en)) {
    es[key] = value;
    ja[key] = value;
    ru[key] = value;
    debug[key] = 'XXXXXX';
}

export default {en, es, ja, ru, debug};
