<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ $t(this.$route.name) }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">{{ $t('Home') }}</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-dollar-sign"></i> {{ $t(this.$route.name) }}</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="form-group col-sm-2">
                        <input class="btn btn-primary" type="button" value="New currency" @click="showCreateForm">&nbsp;&nbsp;
                    </div>
                </div>

                <data-table-wrapper
                    ref="currenciesTable"
                    :columns="columns"
                    :filters="filters"
                    disablePrimaryColumn="true"
                    endpoint="/currencies"
                    sortColumnDefault="code"
                    sortOrderDefault="asc"
                    v-on:edit-request="this.showEditForm"
                    v-on:delete-request="this.showDeleteForm"
                ></data-table-wrapper>
            </div>

            <edit-currency
                v-if="editCurrency"
                :currency="editCurrency"
                :mode="editMode"
                v-on:close="this.hideEditForm"
                v-on:finish="this.loadCurrencies"
            ></edit-currency>

            <delete-currency
                v-if="deleteCurrency"
                :currency="deleteCurrency"
                v-on:close-modal="this.hideDeleteForm"
                v-on:update-items="this.loadCurrencies"
            ></delete-currency>


        </section>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import EditCurrency from '../components/EditCurrency';
import DeleteCurrency from '../components/DeleteCurrency';
import DataTableWrapper from '../components/DataTableWrapper';

export default {
    components: {
        EditCurrency,
        DeleteCurrency,
        DataTableWrapper,
    },
    data() {
        return {
            editCurrency: null,
            editMode: null,
            deleteCurrency: null,
            columns: [
                {
                    field: 'createdAt',
                    title: 'Created',
                    isDate: true,
                },
                {
                    field: 'updatedAt',
                    title: 'Updated',
                    isDate: true,
                },
                {
                    field: 'code',
                    title: 'Code',
                },
                {
                    field: 'name',
                    title: 'Name',
                },
                {
                    field: 'subunits',
                    title: 'Subunits',
                },
                {
                    field: 'rate',
                    title: 'Rate to EUR',
                }
            ],
            filters: [
                {
                    field: 'code',
                    placeholder: 'Enter code',
                    label: 'Code',
                },
                {
                    field: 'name',
                    placeholder: 'Enter name',
                    label: 'Name',
                },
                {
                    field: 'type',
                    label: 'Type:',
                    type: 'btn-group',
                    buttons: [
                        {
                            value: null,
                            label: 'All'
                        },
                        {
                            value: 'crypto',
                            label: 'Crypto'
                        },
                        {
                            value: 'fiat',
                            label: 'Fiat'
                        }
                    ]
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'isAdmin',
            'isShowDebugTools',
        ]),
    },
    methods: {
        showCreateForm() {
            let data = {
                sysname: '',
                name: '',
                rate: 0,
                factor: 0,
                isFiat: false,
                isCrypto: false,
            };

            this.editMode = 'create';
            this.editCurrency = data;
        },
        showEditForm(data) {
            this.editMode = 'edit';
            this.editCurrency = {...data};
        },
        hideEditForm() {
            this.editMode = null;
            this.editCurrency = null;
        },
        showDeleteForm(data) {
            this.deleteCurrency = {...data};
        },
        hideDeleteForm() {
            this.deleteCurrency = null;
        },
        loadCurrencies() {
            this.$refs.currenciesTable.loadData();
        },
    },
    mounted() {
    },
};
</script>
