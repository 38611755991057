<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <data-table-wrapper
                    ref="dataTable"
                    :columns="columns"
                    :filters="filters"
                    :filter-defaults="filterDefaults"
                    :cell-style-option="cellStyleOption"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    endpoint="/sessions"
                    api-service="analytics"
                    sort-column-default="sessionCreatedAt"
                    sort-order-default="DESC"
                >
                    <template v-slot:filter-buttons>
                        <button class="btn btn-default float-right" @click="doExport">
                            <span v-if="!exportInProgress">
                                <i class="fa fa-cloud-download"></i> {{ $t('Export') }}
                            </span>
                            <span v-else>
                                <i class="fas fa-spinner fa-pulse"></i> {{ $t('Export') }}
                            </span>
                        </button>
                    </template>
                </data-table-wrapper>
            </div>

        </section>
    </div>
</template>

<script>
import * as formatUtils from '../helpers/formatUtils';
import * as dateUtils from '../helpers/dateUtils';
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from "vuex";

export default {
    components: {
        DataTableWrapper,
    },
    data() {
        let self = this;
        return {
            exportInProgress: false,
            cellStyleOption: {
                bodyCellClass: ({ column }) => {
                    if (column.field === 'sessionKey') {
                        return 'small-text';
                    }
                },
            },
            filters: [
                {
                    field: 'fromDate',
                    placeholder: 'From Date',
                    label: 'From Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'toDate',
                    placeholder: 'To Date',
                    label: 'To Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'partnerIds',
                    placeholder: 'Select partners',
                    label: 'Partners',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (partners) => { return partners ? partners.map(partner => partner.id) :[]},
                    getOptions: () => this.getPartners,
                    trackBy: 'id'
                },
                {
                    field: 'operatorIds',
                    placeholder: 'Select operators',
                    label: 'Operators',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (operators) => { return operators ? operators.map(operator => operator.id) : [] },
                    getOptions: () => this.getOperatorsOptions,
                    trackBy: 'id'
                },
                {
                    field: 'gameIds',
                    placeholder: 'Select games',
                    label: 'Games',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (games) => { return games ? games.map(game => game.id) : [] },
                    getOptions: () => this.getGames,
                    trackBy: 'id'
                },
                {
                    field: 'sessionKey',
                    placeholder: 'Session Key',
                    label: 'Session Key',
                },
                {
                    field: 'uid',
                    placeholder: 'User ID',
                    label: 'User ID',
                },
                {
                    field: 'username',
                    placeholder: 'Username',
                    label: 'Username',
                },
                {
                    field: 'currency',
                    placeholder: 'Currency',
                    label: 'Currency',
                },
            ],
            columns: [
                {
                    field: 'sessionCreatedAt',
                    title: 'Session Start',
                    sorting: true,
                    isDate: true
                },
                {
                    field: 'sessionClosedAt',
                    title: 'Session Close',
                    sorting: false,
                    isDate: true
                },
                {
                    field: 'partnerId',
                    title: 'Partner',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getPartner(row.partnerId.toString()).name;
                    },
                },
                {
                    field: 'operatorId',
                    title: 'Operator',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getOperator(row.operatorId.toString()).name;
                    },
                },
                {
                    field: 'uid',
                    title: 'User ID',
                    sorting: false,
                },
                {
                    field: 'username',
                    title: 'Username',
                    sorting: false,
                },
                {
                    field: 'gameId',
                    title: 'Game',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getGame(row.gameId).name;
                    },
                },
                {
                    field: 'currency',
                    title: 'Currency',
                    sorting: false,
                },
                {
                    field: 'ggr',
                    title: 'GGR',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.debit - row.credit)
                    },
                },
                {
                    field: 'debit',
                    title: 'Bets',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.debit)
                    },
                },
                {
                    field: 'credit',
                    title: 'Wins',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.credit)
                    },
                },
                {
                    field: 'rate',
                    title: 'Rate EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let currency = this.getCurrency(row.currency);
                        if (currency === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(currency.rate)
                    },
                },
                {
                    field: 'ggr_eur',
                    title: 'GGR EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let currency = this.getCurrency(row.currency);
                        if (currency === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed((row.debit - row.credit) * currency.rate)
                    },
                },
                {
                    field: 'debit_eur',
                    title: 'Bets EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let currency = this.getCurrency(row.currency);
                        if (currency === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(row.debit * currency.rate)
                    },
                },
                {
                    field: 'credit_eur',
                    title: 'Wins EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let currency = this.getCurrency(row.currency);
                        if (currency === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(row.credit * currency.rate)
                    },
                },
                {
                    field: 'bets',
                    title: 'Bets Count',
                    sorting: false,
                },
                {
                    field: 'payout',
                    title: 'Payout, %',
                    sorting: false,
                    callback: ({row}) => {
                        let value = row.credit / row.debit * 100;
                        if (value === Infinity || isNaN(value)) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(value, 2)
                    },
                },
                {
                    field: 'rtp',
                    title: 'RTP, %',
                    sorting: false,
                    callback: ({row}) => {
                        let value = row.creditPerBet / row.debitPerBet * 100;
                        if (value === Infinity || isNaN(value)) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(value, 2)
                    },
                },
                {
                    field: 'sessionKey',
                    title: 'Session Key',
                    sorting: false,
                    callback: ({ row }) => {
                        return (
                            <router-link to={{ name: 'sessionGameActions', params: { sessionKey: row.sessionKey }}}>
                            { row.sessionKey }
                            </router-link>
                        );
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'getGames',
            'getGame',
            'getPartners',
            'getPartner',
            'getOperators',
            'getOperator',
            'getCurrency',
            'isOperator',
        ]),
        getOperatorsOptions() {
            return this.getOperators.filter((operator) => {
                let selectedPartnerIds = this.$refs.dataTable.getFilterValue('partnerIds');
                if (selectedPartnerIds.length === 0) {
                    return true;
                }

                if (!operator.partnerId) {
                    return false;
                }

                return selectedPartnerIds.includes(operator.partnerId);
            });
        },
        filterDefaults() {
            return {
                fromDate: dateUtils.getTodayDate()
            }
        }
    },
    methods: {
        doExport() {
            if (this.exportInProgress) {
                return;
            }
            this.exportInProgress = true;
            this.$exportService.doExport('sessions', this.$refs.dataTable.getPageQuery()).finally(() => {
                this.exportInProgress = false;
            });
        },
    },
    mounted() {
    },
};
</script>
