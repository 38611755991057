<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <data-table-wrapper
                    ref="dataTable"
                    :columns="columns"
                    :filters="filters"
                    :filter-defaults="filterDefaults"
                    :show-filters="true"
                    :cell-style-option="cellStyleOption"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    endpoint="/transactions"
                    api-service="analytics"
                    sort-column-default="timestamp"
                    sort-order-default="DESC"
                >
                    <template v-slot:filter-buttons>
                        <button class="btn btn-default float-right" @click="doExport">
                            <span v-if="!exportInProgress">
                                <i class="fa fa-cloud-download"></i> {{ $t('Export') }}
                            </span>
                            <span v-else>
                                <i class="fas fa-spinner fa-pulse"></i> {{ $t('Export') }}
                            </span>
                        </button>
                    </template>
                </data-table-wrapper>
            </div>

        </section>
    </div>
</template>

<script>
import * as formatUtils from '../helpers/formatUtils';
import * as dateUtils from '../helpers/dateUtils';
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from "vuex";

export default {
    components: {
        DataTableWrapper,
    },
    data() {
        let self = this;
        return {
            exportInProgress: false,
            cellStyleOption: {
                bodyCellClass: ({ column }) => {
                    if (column.field === 'sessionKey' || column.field === 'userAgent') {
                        return 'small-text';
                    }
                },
            },
            filters: [
                {
                    field: 'fromDate',
                    placeholder: 'From Date',
                    label: 'From Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'toDate',
                    placeholder: 'To Date',
                    label: 'To Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'partnerIds',
                    placeholder: 'Select partners',
                    label: 'Partners',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (partners) => { return partners ? partners.map(partner => partner.id) :[]},
                    getOptions: () => this.getPartners,
                    trackBy: 'id'
                },
                {
                    field: 'operatorIds',
                    placeholder: 'Select operators',
                    label: 'Operators',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (operators) => { return operators ? operators.map(operator => operator.id) : [] },
                    getOptions: () => this.getOperatorsOptions,
                    trackBy: 'id'
                },
                {
                    field: 'gameIds',
                    placeholder: 'Select games',
                    label: 'Games',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (games) => { return games ? games.map(game => game.id) : [] },
                    getOptions: () => this.getGames,
                    trackBy: 'id'
                },
                {
                    field: 'sessionKey',
                    placeholder: 'Session key',
                    label: 'Session',
                },
                {
                    field: 'uid',
                    placeholder: 'User ID',
                    label: 'User ID',
                },
                {
                    field: 'username',
                    placeholder: 'Username',
                    label: 'Username',
                },
                {
                    field: 'transactionTypes',
                    placeholder: 'Select types',
                    label: 'Transaction types',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (types) => { return types ? types.map(type => type.id) : [] },
                    getOptions: () => [{id: 'debit', name: 'Debit'}, {id: 'credit', name: 'Credit'}, {id: 'rollback', name: 'Rollback'}],
                    trackBy: 'id'
                },
                {
                    field: 'currency',
                    placeholder: 'Currency',
                    label: 'Currency',
                },
                {
                    field: 'roundId',
                    placeholder: 'Spin ID',
                    label: 'Spin ID',
                },
                {
                    field: 'transactionId',
                    placeholder: 'Transaction ID',
                    label: 'Transaction ID',
                },
                {
                    field: 'externalId',
                    placeholder: 'External ID',
                    label: 'External ID',
                },
                {
                    field: 'errorCode',
                    placeholder: 'Error code',
                    label: 'Error code',
                },
            ],
            columns: [
                {
                    field: 'timestamp',
                    title: 'Date',
                    sorting: true,
                    isDate: true
                },
                {
                    field: 'partnerId',
                    title: 'Partner',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getPartner(row.partnerId.toString()).name;
                    },
                },
                {
                    field: 'operatorId',
                    title: 'Operator',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getOperator(row.operatorId.toString()).name;
                    },
                },
                {
                    field: 'roundId',
                    title: 'Spin ID',
                    sorting: false,
                },
                {
                    field: 'uid',
                    title: 'User ID',
                    sorting: false,
                },
                {
                    field: 'username',
                    title: 'Username',
                    sorting: false,
                },
                {
                    field: 'gameId',
                    title: 'Game',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getGame(row.gameId).name;
                    },
                },
                {
                    field: 'transactionType',
                    title: 'Transaction Type',
                    sorting: false,
                },
                {
                    field: 'transactionId',
                    title: 'Transaction ID',
                    sorting: false,
                },
                {
                    field: 'externalId',
                    title: 'External ID',
                    sorting: false,
                },
                {
                    field: 'currency',
                    title: 'Currency',
                    sorting: false,
                },
                {
                    field: 'transactionAmount',
                    title: 'Amount',
                    sorting: false,
                    callback: ({row}) => {
                        let sign = row.transactionType === 'debit' ? '+' : '-'
                        return sign + formatUtils.formatNumberToFixed(row.transactionAmount);
                    },
                },
                {
                    field: 'balanceAmount',
                    title: 'Balance',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.balanceAmount);
                    },
                },
                {
                    field: 'transactionAmount',
                    title: 'Amount (EUR)',
                    sorting: false,
                    callback: ({row}) => {
                        let currency = self.getCurrency(row.currency);
                        if (currency === undefined) {
                            return 'n/a';
                        }
                        let amount = parseFloat(row.transactionAmount) * currency.rate;
                        let sign = row.transactionType === 'debit' ? '+' : '-'
                        return sign + formatUtils.formatNumberToFixed(amount);
                    },
                },
                {
                    field: 'transactionErrorCode',
                    title: 'Error',
                    sorting: false,
                },
                {
                    field: 'ip',
                    title: 'IP',
                    sorting: false,
                },
                {
                    field: 'deviceType',
                    title: 'Device Type',
                    sorting: false,
                },
                {
                    field: 'countryCode',
                    title: 'Country',
                    sorting: false,
                },
                {
                    field: 'userAgent',
                    title: 'User Agent',
                    sorting: false,
                },
                {
                    field: 'sessionKey',
                    title: 'Session Key',
                    sorting: false,
                    callback: ({ row }) => {
                        return (
                            <router-link to={{ name: 'sessions', query: { sessionKey: row.sessionKey, fromDate: null }}}>
                                { row.sessionKey }
                            </router-link>
                        );
                    },
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'getGames',
            'getGame',
            'getPartners',
            'getPartner',
            'getOperators',
            'getOperator',
            'getCurrency',
            'isOperator',
        ]),
        getOperatorsOptions() {
            return this.getOperators.filter((operator) => {
                let selectedPartnerIds = this.$refs.dataTable.getFilterValue('partnerIds');
                if (selectedPartnerIds.length === 0) {
                    return true;
                }

                if (!operator.partnerId) {
                    return false;
                }

                return selectedPartnerIds.includes(operator.partnerId);
            });
        },
        filterDefaults() {
            return {
                fromDate: dateUtils.getTodayDate()
            }
        }
    },
    methods: {
        doExport() {
            if (this.exportInProgress) {
                return;
            }
            this.exportInProgress = true;
            this.$exportService.doExport('transactions', this.$refs.dataTable.getPageQuery()).finally(() => {
                this.exportInProgress = false;
            });
        },
    },
    mounted() {
    },
};
</script>
