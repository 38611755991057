<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-handshake"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="form-group col-sm-2">
                        <input class="btn btn-primary" type="button" value="New partner" @click="showCreateForm">&nbsp;&nbsp;
                    </div>
                </div>

                <data-table-wrapper
                    ref="partnersTable"
                    :columns="columns"
                    :filters="filters"
                    endpoint="/partners"
                    sort-column-default="id"
                    sort-order-default="asc"
                    v-on:edit-request="this.showEditForm"
                    v-on:delete-request="this.showDeleteForm"
                ></data-table-wrapper>
            </div>

            <edit-partner
                v-if="editPartner"
                :mode="editMode"
                :partner="editPartner"
                v-on:close="this.hideEditForm"
                v-on:finish="this.loadData"
            ></edit-partner>

            <delete-partner
                v-if="deletePartner"
                :partner="deletePartner"
                v-on:close-modal="this.hideDeleteForm"
                v-on:update-items="this.loadData"
            ></delete-partner>

        </section>
    </div>
</template>

<script>
import EditPartner from '../components/EditPartner';
import DeletePartner from '../components/DeletePartner';
import {mapGetters} from 'vuex';
import DataTableWrapper from '../components/DataTableWrapper';


export default {
    components: {
        DataTableWrapper,
        EditPartner,
        DeletePartner,
    },
    data() {
        return {
            columns: [
                {
                    field: 'createdAt',
                    title: 'Created',
                    sorting: true,
                    isDate: true,
                },
                {
                    field: 'name',
                    title: 'Name',
                    sorting: true,
                    filterEnabled: true,
                },
                {
                    field: 'businessName',
                    title: 'Business name',
                    filterEnabled: true,
                },
                {
                    field: 'contactEmail',
                    title: 'Contact email',
                    filterEnabled: true,
                },
                {
                    field: 'contactPerson',
                    title: 'Contact person',
                    filterEnabled: true,
                },
                {
                    field: 'address',
                    title: 'Address',
                    filterEnabled: true,
                },
                {
                    field: 'notes',
                    title: 'Notes',
                    filterEnabled: true,
                },
            ],

            filters: [
                {
                    field: 'name',
                    placeholder: 'Enter name',
                    label: 'Name',
                },
                {
                    field: 'businessName',
                    placeholder: 'Enter business name',
                    label: 'Business name',
                },
                {
                    field: 'contactEmail',
                    placeholder: 'Enter contact email',
                    label: 'Contact email',
                },
                {
                    field: 'contactPerson',
                    placeholder: 'Enter contact person',
                    label: 'Contact person',
                },
                {
                    field: 'address',
                    placeholder: 'Enter address',
                    label: 'Address',
                },
                {
                    field: 'notes',
                    placeholder: 'Enter notes',
                    label: 'Notes',
                },
            ],

            editPartner: null,
            editMode: null,
            deletePartner: null,
        };
    },
    computed: {
        ...mapGetters([
            'isDebugEnabled',
            'isShowDebugTools',
        ]),
    },
    methods: {
        showCreateForm() {
            let data = {
                id: '',
                name: '',
                businessName: '',
                contactPerson: '',
                contactEmail: '',
                address: '',
                notes: '',
                status: '',
            };

            this.editMode = 'create';
            this.editPartner = data;
        },
        showEditForm(data) {
            this.editMode = 'edit';
            this.editPartner = {...data};
        },
        hideEditForm() {
            this.editMode = null;
            this.editPartner = null;
        },
        showDeleteForm(data) {
            this.deletePartner = {...data};
        },
        hideDeleteForm() {
            this.deletePartner = null;
        },
        loadData() {
            this.$refs.partnersTable.loadData();
        },
    },
    mounted() {
        // this.loadPartners();
    },
};
</script>
