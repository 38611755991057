export function getStartOfPreviousMonthDate() {
    let date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth() - 1);
    date.setHours(0, 0, 0, 0);

    return date;
}

export function getStartOfCurrentMonthDate() {
    let date = new Date();

    date.setDate(1);
    date.setMonth(date.getMonth());
    date.setHours(0, 0, 0, 0);

    return date;
}

export function getShiftedDate(baseDate, monthShift = 0, dayShift = 0) {
    let date = new Date();
    date.setHours(0, 0, 0, 0);

    if (baseDate) {
        date = new Date(baseDate);
    }

    date.setDate(date.getDate() + dayShift);
    date.setMonth(date.getMonth() + monthShift);

    return date;
}

export function getToday() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);

    return date;
}

export function getYesterday() {
    let date = new Date();
    date.setHours(0, 0, 0, 0);
    date.setDate(date.getDate() - 1);

    return date;
}

export function getUtcNow() {
    let now = new Date();

    return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
}

export function getTodayDate() {
    return new Date().toISOString().slice(0, 10) + ' 00:00:00';
}

export function formatToStartOfDay(date) {
    return date.toISOString().slice(0, 10) + ' 00:00:00';
}

export function getFilterShortcuts() {
    return [
        {
            text: 'Today',
            onClick: getToday,
        },
        {
            text: 'Yesterday',
            onClick: getYesterday,
        },
        {
            text: 'This month',
            onClick: getStartOfCurrentMonthDate,
        },
        {
            text: 'Prev. month',
            onClick: getStartOfPreviousMonthDate,
        },
        {
            text: 'Now (UTC)',
            onClick: getUtcNow,
        },
    ];
}
