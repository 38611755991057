<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form @submit="submit">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ this.isCreateMode ? 'Create' : 'Edit' }} excluded user</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Operator</label>
                            <multiselect
                                v-model="operator"
                                :deselect-label="$t('Press enter to remove')"
                                :multiple="false"
                                :options="this.getOperators"
                                :placeholder="$t('Select operator')"
                                :select-label="$t('Press enter to select')"
                                :selected-label="$t('Selected')"
                                label="name"
                                track-by="id"
                            ></multiselect>

                        </div>
                        <div class="form-group">
                            <label>UID</label>
                            <input v-model="uid"
                                   autocomplete="off"
                                   class="form-control"
                                   name="business-name"
                                   placeholder="Enter business name"
                                   required
                                   type="text"
                            >
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
#modal-default {
    display: block;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import {mapGetters} from "vuex";
import Vue from "vue";

const MODE_CREATE = 'create';

export default {
    props: [
        'mode',
    ],
    components: {
        Multiselect,
    },
    computed: {
        isCreateMode() {
            return this.mode === MODE_CREATE;
        },
        isDebug() {
            return process.env.NODE_ENV === 'development';
        },
        ...mapGetters([
            'getOperators',
        ]),
    },
    methods: {
        submit(e) {
            e.preventDefault();


            let method = 'POST';
            let url = Vue.config.analyticsURL + '/excluded-users';

            this.$http({
                url: url,
                data: {
                    operatorId: parseInt(this.operator.id),
                    uid: this.uid,
                },
                method: method,
            }).then(() => {
                this.$emit('finish');
                this.$emit('close');
            }).catch((err) => {
                console.error(err);
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
    data() {
        return {
            operator: null,
            uid: null,
        };
    },
};

</script>
