<template>
    <div class="form-group">
        <button v-if="!this.cancel" class="btn btn-primary" @click="this.loadFn">
            <i class="fa fa-play"></i>&nbsp;{{ $t('Search') }}
        </button>
        <span v-if="!this.cancel">&nbsp;&nbsp;</span>

        <button v-if="this.cancelFn && this.cancel" class="btn btn-primary" @click="this.cancelFn">
            <i class="fa fa-stop"></i>&nbsp;{{ $t('Cancel') }}
        </button>
        <span v-if="this.cancelFn && this.cancel">&nbsp;&nbsp;</span>

        <button class="btn btn-default" @click="this.resetFn">
            {{ $t('Reset') }}
        </button>
        <span>&nbsp;&nbsp;</span>

        <b-button
            v-if="this.toggleFn"
            :pressed="this.toggleState"
            title="Toggle advanced filter"
            variant="outline-secondary"
            @click="this.toggleFn">
            <i class="fa fa-sliders-h"></i>&nbsp;{{ $t('Advanced') }}
        </b-button>
    </div>
</template>

<script>

export default {
    props: [
        'cancel',
        'loadFn',
        'cancelFn',
        'resetFn',
        'toggleFn',
        'toggleState',
    ],
};
</script>
