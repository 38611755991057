<template>
    <div class="row col-lg-12">
        <div v-for="filter in advancedFilters" :key="filter.field" class="col-lg-4 col-md-4 col-sm-12">
            <b-form-group v-if="!filter.type" :label="filter.label + ': '" :label-for="getElementInputId(filter)"
                          label-cols-lg="3">
                <b-form-input :id="getElementInputId(filter)"
                              v-model="filters[filter.field]"
                              :placeholder="filter.placeholder"
                              autocomplete="off"
                              trim type="search">
                </b-form-input>
            </b-form-group>
            <b-form-group v-if="filter.type === 'multiselect'" :label="filter.label"
                          :label-for="getElementInputId(filter)"
                          label-cols-lg="3">
                <multiselect :id="getElementInputId(filter)"
                             v-model="filters[filter.field]"
                             :deselect-label="filter.deselectLabel"
                             :label="filter.optionLabel"
                             :multiple="filter.multiple"
                             :options="filter.getOptions()"
                             :placeholder="filter.placeholder"
                             :select-label="filter.selectLabel"
                             :selected-label="filter.selectedLabel"
                             :track-by="filter.trackBy"
                             @select="(value, id) => { filter.onSelect ? filter.onSelect(value, id) : null}"
                ></multiselect>
            </b-form-group>
            <b-form-group v-if="filter.type === 'date-picker'"
                          :label="filter.label"
                          label-cols-lg="3"
                          :label-for="getElementInputId(filter)">
                <date-picker :id="getElementInputId(filter)"
                             v-model="filters[filter.field]"
                             :placeholder="filter.placeholder"
                             :shortcuts="filter.shortcuts"
                             input-class="form-control"
                             type="datetime"
                             valueType="format"
                ></date-picker>
            </b-form-group>
            <div v-if="filter.type === 'checkbox'" class="form-group form-check">
                <input :id="getElementInputId(filter)"
                       v-model="filters[filter.field]"
                       class="form-check-input"
                       type="checkbox"
                >
                <label :for="getElementInputId(filter)" class="form-check-label">{{ filter.label }}: </label>
            </div>
            <b-form-group v-if="filter.type === 'btn-group'"
                          :label="filter.label"
                          label-cols-lg="3"
                          :label-for="getElementInputId(filter)">
                <div class="btn-group btn-group-toggle" data-toggle="buttons">
                    <button v-for="btn in filter.buttons" :key="btn.value" type="button" class="btn"
                            :class="isBtnGroupItemSelected(filter, btn.value) ? 'btn-secondary active' : 'btn-outline-secondary'"
                            v-on:click="selectBtnGroupValue(filter, btn.value)"
                    >{{ btn.label }}</button>
                </div>
            </b-form-group>
        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import DatePicker from 'vue2-datepicker';
import Vue from "vue";

export default {
    components: {
        Multiselect,
        DatePicker
    },
    props: [
        'advancedFilters',
        'filters',
    ],
    methods: {
        getElementInputId(filter) {
            const field = filter.field;

            return 'input_' + field;
        },
        isBtnGroupItemSelected(filter, value) {
            if (!value && !this.filters[filter.field]) {
                return true;
            }
            return this.filters[filter.field] === value;
        },
        selectBtnGroupValue(filter, value) {
            Vue.set(this.filters, filter.field, value);
        }
    },
};
</script>
