<template>
    <div>
        <b-modal
            v-model="modalShow"
            size="lg"
            :title="'Import to ' + title"
            :static="true"
            :lazy="true"
            hide-footer
        >
            <div class="modal-body">
                <form class="">
                    <div class="form-row">
                        <div class="form-group col">
                            <label class="form-label">Language</label>
                            <b-form-select v-model="code" :options="codeOptions"></b-form-select>
                        </div>
                        <div class="form-group col"><label class="form-label">Format</label>
                            <div class="pt-1">
                                <div class="form-check form-check-inline">
                                    <b-form-radio v-model="format" name="format" value="json">json</b-form-radio>
                                </div>
                                <div class="form-check form-check-inline">
                                    <b-form-radio v-model="format" name="format" value="csv">csv</b-form-radio>
                                </div>
                            </div>
                        </div>
                        <div class="form-group col">
                            <label class="form-label">File</label>
                            <div class="form-file">
                                <input ref="fileInput" name="file" type="file" class="form-control-file">
                            </div>
                        </div>
                        <div class="d-flex align-items-end justify-content-end form-group col-sm-2">
                            <button type="button" @click="importTranslation" class="btn btn-success btn-sm">Import
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </b-modal>
    </div>
</template>

<script>


import {mapGetters} from "vuex";

export default {
    data() {
        return {
            modalShow: false,
            format: 'json',
            code: 'en',
            groupId: null
        }
    },
    components: {},
    props: [
        'languages'
    ],
    watch: {},
    methods: {
        importTranslation() {
            const fd = new FormData();
            fd.append('groupId', this.groupId);
            fd.append('languageId', this.code);
            fd.append('format', this.format);
            fd.append('file', this.$refs.fileInput.files[0]);

            this
                .$http({
                    url: '/localization/translations/import',
                    method: 'POST',
                    data: fd,
                    headers: {"Content-Type": "multipart/form-data"},
                })
                .then(() => {
                    this.$emit('onComplete');
                    this.modalShow = false;

                })
                .catch((err) => {
                    console.log(err);
                })
        },
        show(groupId) {
            this.groupId = groupId;
            this.format = 'json';
            this.code = 'en';
            this.modalShow = true;
        },
    },
    computed: {
        ...mapGetters([
            'getGame'
        ]),
        title() {
            if (this.groupId === null) {
                return '';
            }
            if (this.groupId === 0) {
                return 'All Games';
            }
            return this.getGame(this.groupId).name;
        },
        codeOptions() {
            let options = this.languages
                .map((item) => {
                    return {value: item.id, text: item.name + ' (' + item.id + ')'}
                })
                .sort((a, b) => a.text > b.text ? 1 : -1);
            options.unshift({value: 'all', text: 'All'});

            return options;
        }
    },
    mounted() {
    },
}
</script>
