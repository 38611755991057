<template>
    <div class="login-box">
        <div class="login-logo">
            <strong>{{ appName }}</strong>
        </div>
        <div class="card">
            <div class="card-body login-card-body">
                <p class="login-box-msg">Sign in to start your session</p>

                <form @submit.prevent="doLogin">
                    <div class="input-group mb-3">
                        <input v-model="username" class="form-control" placeholder="Login" required type="text">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-user"></span>
                            </div>
                        </div>
                    </div>
                    <div class="input-group mb-3">
                        <input v-model="password" class="form-control" placeholder="Password" required type="password">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8 col-sm-2">
                        </div>
                        <div class="col-md-4 col-sm-10">
                            <button class="btn btn-primary btn-block" type="submit">Sign In</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
    name: 'Login',
    components: {},
    methods: {
        doLogin: function() {
            let username = this.username;
            let password = this.password;

            this.$store.dispatch('login', {
                username,
                password,
            }).catch((error) => {
                if (error.response && error.response.data.error) {
                    this.$toastr.e(error.response.data.error);
                    return;
                }
                this.$router.push('/login');
            });
        },
    },
    computed: {
        ...mapGetters([
            'getCurrentUser',
        ]),
    },
    created() {
        if (this.getCurrentUser) {
            this.$router.push('/');
        }
    },
    watch: {
        getCurrentUser(newValue) {
            if (newValue) {
                this.$router.push('/');
            }
        },
    },
    mounted() {
        if (this.getCurrentUser) {
            this.$router.push('/');
        }
        document.body.classList.add('hold-transition', 'login-page');
    },
    beforeDestroy() {
        document.body.classList.remove('hold-transition', 'login-page');
    },
    data() {
        return {
            username: '',
            password: '',
            appName: process.env.VUE_APP_NAME,
        };
    },
};
</script>
