<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">{{ $t('Summary charts') }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><router-link :to="{name: 'dashboard'}">{{ $t('Dashboard') }}</router-link></li>
                            <li class="breadcrumb-item active text-capitalize"> {{ $t('Summary charts') }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="From date:" label-cols-lg="3" label-for="filter-fromDate">
                            <date-picker id="filter-fromDate"
                                         v-model="filters.fromDate"
                                         :placeholder="$t('From date')"
                                         :shortcuts="dateShortcuts"
                                         input-class="form-control"
                                         type="datetime"
                                         valueType="format"
                            ></date-picker>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="To date:" label-cols-lg="3" label-for="filter-toDate">
                            <date-picker id="filter-toDate"
                                         v-model="filters.toDate"
                                         :placeholder="$t('To date')"
                                         :shortcuts="dateShortcuts"
                                         input-class="form-control"
                                         type="datetime"
                                         valueType="format"
                            ></date-picker>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Users:" label-cols-lg="3" label-for="excluded-users">
                            <b-form-select v-model="filters.excludedUsers" :options="[{value: 'exclude', text: 'Without Excluded'}, {value: 'include', text: 'With Excluded'}, {value: 'only', text: 'Only Excluded'}]">

                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Partners:" label-cols-lg="3" label-for="filter-partners">
                            <multiselect id="filter-partners"
                                         v-model="filters.partners"
                                         :deselect-label="$t('Press enter to remove')"
                                         :multiple="true"
                                         :options="this.getPartners"
                                         :placeholder="$t('Select partners')"
                                         :select-label="$t('Press enter to select')"
                                         :selected-label="$t('Selected')"
                                         label="name"
                                         track-by="id"
                            ></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Operators:" label-cols-lg="3" label-for="filter-operators">
                            <multiselect id="filter-operators"
                                         v-model="filters.operators"
                                         :deselect-label="$t('Press enter to remove')"
                                         :multiple="true"
                                         :options="this.getOperatorsOptions"
                                         :placeholder="$t('Select operators')"
                                         :select-label="$t('Press enter to select')"
                                         :selected-label="$t('Selected')"
                                         label="name"
                                         track-by="id"
                            ></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Games:" label-cols-lg="3" label-for="filter-games">
                            <multiselect id="filter-games"
                                         v-model="filters.games"
                                         :deselect-label="$t('Press enter to remove')"
                                         :multiple="true"
                                         :options="this.getGames"
                                         :placeholder="$t('Select game')"
                                         :select-label="$t('Press enter to select')"
                                         :selected-label="$t('Selected')"
                                         label="name"
                                         track-by="id"
                            ></multiselect>
                        </b-form-group>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col-lg-12 col-md-12 col-sm-12 apply-clear">
                        <div class="form-group">
                            <button class="btn btn-primary" @click="applyFilters">
                                <i class="fa fa-play"></i>&nbsp;{{ $t('Search') }}
                            </button>
                            &nbsp;
                            <button class="btn btn-default" @click="clearFilters">
                                {{ $t('Reset') }}
                            </button>

                            <button class="btn btn-default float-right" @click="doExport">
                            <span v-if="!exportInProgress">
                                <i class="fa fa-cloud-download"></i> {{ $t('Export') }}
                            </span>
                                <span v-else>
                                <i class="fas fa-spinner fa-pulse"></i> {{ $t('Export') }}
                            </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                        <b-form-group>
                            <div class="btn-group btn-group-toggle " data-toggle="buttons">
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'ggr' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('ggr')"
                                >{{ $t('GGR') }}, €</button>
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'bets' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('bets')"
                                >{{ $t('Bets') }}, €</button>
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'wins' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('wins')"
                                >{{ $t('Wins') }}, €</button>
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'betsCount' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('betsCount')"
                                >{{ $t('Bets') }}</button>
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'playersCount' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('playersCount')"
                                >{{ $t('Players') }}</button>
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'payout' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('payout')"
                                >{{ $t('Pay out, %') }}</button>
                                <button type="button" class="btn"
                                        :class="this.filters.type === 'rtp' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setDataset('rtp')"
                                >{{ $t('RTP, %') }}</button>
                            </div>
                        </b-form-group>
                    </div>
                </div>

                <div id="loading-container" class="bg-white" style="min-height: 300px; padding: 20px">
                    <Bar v-if="loaded"
                         :chart-options="chartOptions"
                         :chart-data="chartData"
                         :chart-id="chartId"
                         :dataset-id-key="datasetIdKey"
                         :plugins="plugins"
                         :css-classes="cssClasses"
                         :styles="styles"
                         :width="width"
                         :height="height"
                    />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
import 'vue2-datepicker/index.css';
import {mapGetters} from 'vuex';
import { Bar } from 'vue-chartjs/legacy'
import axios from 'axios';
import * as dateUtils from '../helpers/dateUtils';
import FilterButtons from '../components/FilterButtons';
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'
import Vue from "vue";
import Moment from "moment";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
const CancelToken = axios.CancelToken;

export default {
    name: 'Dashboard',
    components: {
        Bar,
        DatePicker,
        Multiselect,
        FilterButtons,
    },
    watch: {
        partnersFilter() {
            this.filters.operators = this.filters.operators.filter(operator => {
                return this.getOperatorsOptions.map(operatorOptions => operatorOptions.id).includes(operator.id);
            });
        },
    },
    props: {
        chartId: {
            type: String,
            default: 'bar-chart'
        },
        datasetIdKey: {
            type: String,
            default: 'label'
        },
        width: {
            type: Number,
            default: 400
        },
        height: {
            type: Number,
            default: 400
        },
        cssClasses: {
            default: '',
            type: String
        },
        styles: {
            type: Object,
            default: () => {}
        },
        plugins: {
            type: Object,
            default: () => {}
        }
    },
    data: () => ({
        exportInProgress: false,
        cancel: null,
        loaded: false,
        chartDataset: 'ggr',
        chartData: null,
        chartOptions: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
                legend: {
                    display: false,
                },
            },
        },
        labels: [],
        datasets: {
            rtp: {
                label: 'RTP, %',
                data: [],
                backgroundColor: '#7997f8',
            },
            payout: {
                label: 'Pay out, %',
                data: [],
                backgroundColor: '#7997f8',
            },
            ggr: {
                label: 'GGR, €',
                data: [],
                backgroundColor: '#7997f8',
            },
            bets: {
                label: 'Bets, €',
                data: [],
                backgroundColor: '#7997f8',
            },
            wins: {
                label: 'Wins, €',
                data: [],
                backgroundColor: '#7997f8',
            },
            betsCount: {
                label: 'Bets',
                data: [],
                backgroundColor: '#7997f8',
            },
            playersCount: {
                label: 'Players',
                data: [],
                backgroundColor: '#7997f8',
            }
        },
        appliedFilters: {},
        filters: {
            partners: [],
            operators: [],
            games: [],
            fromDate: null,
            toDate: null,
            type: 'ggr',
            excludedUsers: 'exclude',
        },
        dateShortcuts: dateUtils.getFilterShortcuts(),
    }),
    computed: {
        ...mapGetters([
            'getGames',
            'getPartners',
            'getOperators',
            'getCurrency',
            'isOperator',
        ]),
        pageParams() {
            return {
                partnerIds: this.filters.partners.map(partner => partner.id.toString()),
                operatorIds: this.filters.operators.map(operator => operator.id.toString()),
                fromDate: this.filters.fromDate,
                toDate: this.filters.toDate,
                gameIds: this.filters.games.map(game => game.id.toString()),
                type: this.filters.type,
                excludedUsers: this.filters.excludedUsers
            };
        },
        partnersFilter() {
            return this.filters.partners;
        },
        getOperatorsOptions() {
            return this.getOperators.filter((operator) => {
                if (this.filters.partners.length === 0) {
                    return true;
                }

                if (!operator.partnerId) {
                    return false;
                }

                let selectedPartnerIds = this.filters.partners.map(partner => partner.id);

                return selectedPartnerIds.includes(operator.partnerId);
            });
        },
    },
    methods: {
        setDataset(key) {
            this.filters.type = key;


            this.chartData = {
                labels: this.labels,
                datasets: [this.datasets[this.filters.type]]
            }

            const { href } = this.$router.resolve({query: this.pageParams});
            window.history.replaceState({}, null, href);
        },
        cancelRequest() {
            if (this.cancel) {
                this.cancel('this -> cancel previous request');
            }
        },
        loadData() {

            let loadingInstance = this.$veLoading({
                target: document.querySelector('#loading-container'),
                name: 'wave',
            });
            loadingInstance.show();

            const { href } = this.$router.resolve({query: this.pageParams});
            window.history.replaceState({}, null, href);

            let self = this;
            this.$http({
                url: Vue.config.analyticsURL + '/charts/summary',
                params: this.pageParams,
                method: 'GET',
                cancelToken: new CancelToken(function executor(c) {
                    self.cancel = c;
                }),
            }).then(resp => {
                self.labels = [];
                let itemsByDate = [];

                for (let index in resp.data) {
                    let item = resp.data[index];
                    let date = Moment.utc(item.date).format('YYYY-MM-DD');
                    if (!self.labels.includes(date)) {
                        self.labels.push(date)
                    }

                    let currency = this.getCurrency(item.currency);

                    if (!itemsByDate[item.date]) {
                        itemsByDate[item.date] = {
                            bets: 0,
                            wins: 0,
                            betsPerBet: 0,
                            winsPerBet: 0,
                            betsCount: 0,
                            playersCount: 0,
                            ggr: 0,
                            rtp: 0,
                            payout: 0,
                        }
                    }

                    itemsByDate[item.date].bets += parseFloat(item['debit']) * parseFloat(currency.rate);
                    itemsByDate[item.date].wins += parseFloat(item['credit']) * parseFloat(currency.rate);
                    itemsByDate[item.date].betsPerBet += parseFloat(item.debitPerBet)
                    itemsByDate[item.date].winsPerBet += parseFloat(item.creditPerBet)
                    itemsByDate[item.date].betsCount += item.bets
                    itemsByDate[item.date].playersCount += item.players
                }

                self.datasets.rtp.data = [];
                self.datasets.ggr.data = [];
                self.datasets.payout.data = [];
                self.datasets.bets.data = [];
                self.datasets.wins.data = [];
                self.datasets.betsCount.data = [];
                self.datasets.playersCount.data = [];

                for (let index in itemsByDate) {
                    itemsByDate[index].rtp = itemsByDate[index].winsPerBet / itemsByDate[index].betsPerBet * 100;
                    itemsByDate[index].ggr = itemsByDate[index].bets - itemsByDate[index].wins;
                    itemsByDate[index].payout = itemsByDate[index].wins / itemsByDate[index].bets * 100;

                    self.datasets.rtp.data.push(itemsByDate[index].rtp);
                    self.datasets.ggr.data.push(itemsByDate[index].ggr);
                    self.datasets.payout.data.push(itemsByDate[index].payout);
                    self.datasets.bets.data.push(itemsByDate[index].bets);
                    self.datasets.wins.data.push(itemsByDate[index].wins);
                    self.datasets.betsCount.data.push(itemsByDate[index].betsCount);
                    self.datasets.playersCount.data.push(itemsByDate[index].playersCount);
                }

                self.setDataset(self.filters.type)
                self.loaded = true
            }).catch((err) => {
                console.log(err);
            }).finally(() => {
                loadingInstance.close();
                self.cancel = null;
            });
        },
        doExport() {
            if (this.exportInProgress) {
                return;
            }
            this.exportInProgress = true;
            this.$exportService.doExport('charts/summary', this.pageParams).finally(() => {
                this.exportInProgress = false;
            });
        },
        applyFilters() {
            this.loadData();
        },
        clearFilters() {
            for (const key of Object.getOwnPropertyNames(this.filters)) {
                if (typeof this.filters[key] === 'object') {
                    this.filters[key] = [];
                } else {
                    this.filters[key] = null;
                }
            }
            this.setFilterDefaults();
        },
        setFilterDefaults() {
            this.filters.fromDate = dateUtils.getTodayDate();
            this.filters.excludedUsers = 'exclude';
        },
        setFiltersFromRoute() {
            for (let paramName in this.$route.query) {
                let queryValues = this.$route.query[paramName];
                switch (paramName) {
                    case 'partnerIds':
                        if (!this.$route.query.partnerIds) {
                            break;
                        }

                        if (!Array.isArray(queryValues)) {
                            queryValues = [queryValues]
                        }
                        Vue.set(this.filters, 'partners', this.getPartners.filter(partner => queryValues.includes(partner.id.toString())));
                        break
                    case 'operatorIds':
                        if (!this.$route.query.operatorIds) {
                            break;
                        }

                        if (!Array.isArray(queryValues)) {
                            queryValues = [queryValues]
                        }
                        Vue.set(this.filters, 'operators', this.getOperators.filter(operator => queryValues.includes(operator.id.toString())));
                        break
                    case 'gameIds':
                        if (!this.$route.query.gameIds) {
                            break;
                        }

                        if (!Array.isArray(queryValues)) {
                            queryValues = [queryValues]
                        }
                        Vue.set(this.filters, 'games', this.getGames.filter(game => queryValues.includes(game.id.toString())));
                        break
                    default:
                        Vue.set(this.filters, paramName, this.$route.query[paramName]);
                        break;
                }
            }
        },
    },
    async mounted () {
        this.setFilterDefaults();
        this.setFiltersFromRoute();
        this.applyFilters();
    }
};
</script>
