<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0">{{ $t('Summary') }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item"><a href="/">{{ $t('Dashboard') }}</a></li>
                            <li class="breadcrumb-item active text-capitalize"> {{ $t('Summary') }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>
        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="From date:" label-cols-lg="3" label-for="filter-fromDate">
                            <date-picker id="filter-fromDate"
                                         v-model="filters.fromDate"
                                         :placeholder="$t('From date')"
                                         :shortcuts="dateShortcuts"
                                         input-class="form-control"
                                         type="datetime"
                                         valueType="format"
                            ></date-picker>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="To date:" label-cols-lg="3" label-for="filter-toDate">
                            <date-picker id="filter-toDate"
                                         v-model="filters.toDate"
                                         :placeholder="$t('To date')"
                                         :shortcuts="dateShortcuts"
                                         input-class="form-control"
                                         type="datetime"
                                         valueType="format"
                            ></date-picker>
                        </b-form-group>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Partners:" label-cols-lg="3" label-for="filter-partners">
                            <multiselect id="filter-partners"
                                         v-model="filters.partners"
                                         :deselect-label="$t('Press enter to remove')"
                                         :multiple="true"
                                         :options="this.getPartners"
                                         :placeholder="$t('Select partners')"
                                         :select-label="$t('Press enter to select')"
                                         :selected-label="$t('Selected')"
                                         label="name"
                                         track-by="id"
                            ></multiselect>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Operators:" label-cols-lg="3" label-for="filter-operators">
                            <multiselect id="filter-operators"
                                         v-model="filters.operators"
                                         :deselect-label="$t('Press enter to remove')"
                                         :multiple="true"
                                         :options="this.getOperatorsOptions"
                                         :placeholder="$t('Select operators')"
                                         :select-label="$t('Press enter to select')"
                                         :selected-label="$t('Selected')"
                                         label="name"
                                         track-by="id"
                            ></multiselect>
                        </b-form-group>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Group by:" label-cols-lg="3" label-for="group-by">
                            <div class="btn-group btn-group-toggle" data-toggle="buttons">
                                <button type="button" class="btn"
                                        :class="filters.groupBy === 'game' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setGroupBy('game')"
                                >Game</button>
                                <button type="button" class="btn"
                                        :class="filters.groupBy === 'operator' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setGroupBy('operator')"
                                >Operator</button>
                                <button type="button" class="btn"
                                        :class="filters.groupBy === 'sitegroup' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setGroupBy('sitegroup')"
                                >SiteGroup</button>
                                <button type="button" class="btn"
                                      :class="filters.groupBy === 'partner' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                      v-on:click="setGroupBy('partner')"
                                >Partner</button>
                            </div>
                        </b-form-group>
                    </div>
                    <div class="col-sm-12 col-md-6 col-lg-4">
                        <b-form-group label="Users:" label-cols-lg="3" label-for="excluded-users">
                            <b-form-select v-model="filters.excludedUsers" :options="[{value: 'exclude', text: 'Without Excluded'}, {value: 'include', text: 'With Excluded'}, {value: 'only', text: 'Only Excluded'}]">

                            </b-form-select>
                        </b-form-group>
                    </div>
                </div>
                <div class="row col-lg-12">
                    <div class="col-lg-12 col-md-12 col-sm-12 apply-clear">
                        <div class="form-group">
                            <filter-buttons
                                :cancel="this.cancel"
                                :cancelFn="cancelRequest"
                                :loadFn="applyFilters"
                                :resetFn="clearFilters"
                            ></filter-buttons>
                        </div>
                    </div>
                </div>

                <div class="row col-lg-12">
                    <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                        <b-form-group>
                            <div class="btn-group btn-group-toggle " data-toggle="buttons">
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'a-z' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('a-z')"
                                >{{ $t('A-Z') }} <i v-if="this.filters.orderBy === 'a-z'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'ggr' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('ggr')"
                                >{{ $t('GGR') }}, € <i v-if="this.filters.orderBy === 'ggr'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'bets' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('bets')"
                                >{{ $t('Bets') }}, € <i v-if="this.filters.orderBy === 'bets'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'wins' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('wins')"
                                >{{ $t('Wins') }}, € <i v-if="this.filters.orderBy === 'wins'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'betsCount' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('betsCount')"
                                >{{ $t('Bets') }} <i v-if="this.filters.orderBy === 'betsCount'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'playersCount' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('playersCount')"
                                >{{ $t('Players') }} <i v-if="this.filters.orderBy === 'playersCount'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'payout' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('payout')"
                                >{{ $t('Pay out, %') }} <i v-if="this.filters.orderBy === 'payout'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                                <button type="button" class="btn"
                                        :class="this.filters.orderBy === 'rtp' ? 'btn-secondary active' : 'btn-outline-secondary'"
                                        v-on:click="setOrderBy('rtp')"
                                >{{ $t('RTP, %') }} <i v-if="this.filters.orderBy === 'rtp'" :class="this.filters.orderByDirection === -1 ? 'fa-sort-amount-down' : 'fa-sort-amount-down-alt'" class="fas"></i></button>
                            </div>
                        </b-form-group>
                    </div>
                </div>

                <div id="loading-container" class="row col-lg-12">
                    <div v-for="item in sortedSummaryItems()" :key="item.id" class="col-sm-12 col-md-6 col-lg-4">
                        <div class="card card-default">
                            <div class="card-header">
                                <h3 class="card-title">{{ getCardTitle(item.id) }}</h3>
                            </div>
                            <div class="card-body">
                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'ggr')}">
                                    {{ $t('GGR, €') }}:
                                </router-link> {{
                                    formatNumberToFixed(item.ggr)
                                }}<br/>

                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'bets')}">
                                    {{ $t('Bets, €') }}:
                                </router-link> {{
                                    formatNumberToFixed(item.bets)
                                }}<br/>

                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'wins')}">
                                    {{ $t('Wins, €') }}:
                                </router-link> {{
                                    formatNumberToFixed(item.wins)
                                }}<br/>

                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'betsCount')}">
                                    {{ $t('Bets') }}:
                                </router-link> {{
                                    formatNumberToFixed(item.betsCount, 0)
                                }}<br/>

                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'playersCount')}">
                                    {{ $t('Players') }}:
                                </router-link>
                                {{ formatNumberToFixed(item.playersCount, 0) }} / {{ formatNumberToFixed(item.onlinePlayersCount, 0) }}
                                <br/>
                                <ve-table :columns="perDeviceTypeTableColumns" :table-data="perDeviceTypeTableData(item.perDevice)"/>

                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'payout')}">
                                    {{ $t('Pay out, %') }}:
                                </router-link>
                                {{ item.payout === Infinity || isNaN(item.payout) ? 'n/a' : formatNumberToFixed(item.payout, 2) }}<br/>
                                <router-link :to="{name: 'dashboard.charts', query: getChartsQuery(item.id, 'rtp')}">
                                    {{ $t('RTP, %') }}:
                                </router-link>
                                {{ item.rtp === Infinity || isNaN(item.rtp) ? 'n/a' : formatNumberToFixed(item.rtp, 2) }}<br/>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12">
                    <!--          <load-stop-watch-->
                    <!--              :startedAt="this.startedAt"-->
                    <!--              :finishedAt="this.finishedAt"-->
                    <!--          ></load-stop-watch>-->
                </div>
            </div>
        </section>
    </div>
</template>

<style>
.players-count {
    border-bottom: dotted;
    cursor: pointer;
}
</style>
<script>
import DatePicker from 'vue2-datepicker';
import Multiselect from 'vue-multiselect';
import 'vue2-datepicker/index.css';
import axios from 'axios';
import {mapGetters} from 'vuex';
import * as formatUtils from '../helpers/formatUtils';
import * as dateUtils from '../helpers/dateUtils';
import FilterButtons from '../components/FilterButtons';
import Vue from "vue";

const CancelToken = axios.CancelToken;

let tooltipId = 0;
export default {
    name: 'Dashboard',
    components: {
        DatePicker,
        Multiselect,
        FilterButtons,
    },
    watch: {
        partnersFilter() {
            this.filters.operators = this.filters.operators.filter(operator => {
                return this.getOperatorsOptions.map(operatorOptions => operatorOptions.id).includes(operator.id);
            });
        },
    },
    data() {
        return {
            cancel: null,
            id: this.$route.params.id || 'total',
            partners: [],
            operators: [],
            summaryItems: [],
            appliedFilters: {},
            filters: {
                partners: [],
                operators: [],
                fromDate: null,
                toDate: null,
                groupBy: 'sitegroup',
                excludedUsers: 'exclude',
                orderBy: 'a-z',
                orderByDirection: 1
            },
            startedAt: null,
            finishedAt: null,
            perDeviceTypeTableColumns: [
                {field: 'deviceType', key: 'a', title: 'Platform', width: 100},
                {field: 'playersCount', key: 'b', title: 'Players', width: 200, renderBodyCell: ({row}) => {
                        tooltipId++;
                        return (
                            <p>
                                <span id={'tooltip-' + tooltipId} class="players-count">
                                    { row.playersCount }
                                </span>
                                <b-tooltip target={'tooltip-' + tooltipId} triggers="hover" placement="top">
                                    online: { row.onlinePlayersCount }
                                </b-tooltip>
                            </p>
                        );
                }},
                {field: 'betsCount', key: 'c', title: 'Bets', width: 300},
                {field: 'bets', key: 'd', title: 'Bets, €', width: 300},
            ],
            dateShortcuts: dateUtils.getFilterShortcuts(),
        };
    },
    computed: {
        pageParams() {
            return {
                partnerIds: this.filters.partners.map(partner => partner.id.toString()),
                operatorIds: this.filters.operators.map(operator => operator.id.toString()),
                fromDate: this.filters.fromDate,
                toDate: this.filters.toDate,
                groupBy: this.filters.groupBy,
                excludedUsers: this.filters.excludedUsers
            };
        },
        ...mapGetters([
            'getGames',
            'getPartners',
            'getOperators',
            'getSitegroup',
            'getCurrency',
            'isOperator',
        ]),
        partnersFilter() {
            return this.filters.partners;
        },
        getOperatorsOptions() {
            return this.getOperators.filter((operator) => {
                if (this.filters.partners.length === 0) {
                    return true;
                }

                if (!operator.partnerId) {
                    return false;
                }

                let selectedPartnerIds = this.filters.partners.map(partner => partner.id);

                return selectedPartnerIds.includes(operator.partnerId);
            });
        },
    },
    methods: {
        sortedSummaryItems() {
            return this.summaryItems.slice().sort((itemA, itemB) => {
                if (itemA.id === 'total' && itemB.id !== 'total' || itemB.id === 'total' && itemA.id !== 'total') {
                    return 1;
                }

                switch (this.filters.orderBy) {
                    case 'a-z':
                        return this.getCardTitle(itemA.id) > this.getCardTitle(itemB.id) ? this.filters.orderByDirection : -1 * this.filters.orderByDirection;
                    default:
                        return itemA[this.filters.orderBy] > itemB[this.filters.orderBy] ? this.filters.orderByDirection : -1 * this.filters.orderByDirection;

                }
            })
        },
        setGroupBy(groupBy) {
            this.filters.groupBy = groupBy;
        },
        getChartsQuery(itemId, type) {
            let params = {
                ...this.pageParams,
                gameIds: [],
                type: type
            };

            if (itemId === 'total') {
                return params;
            }

            if (this.appliedFilters.groupBy === 'operator') {
                params.operatorIds = itemId.toString();
                return params;
            }
            params.gameIds = itemId.toString();
            return params;
        },
        getCardTitle(groupId) {
            if (groupId === 'total') {
                return this.$t('Total');
            }

            if (this.appliedFilters.groupBy === 'operator') {
                let operator = this.getOperators.find((operator) => String(operator.id) === String(groupId));
                return operator.name;
            }

            if (this.appliedFilters.groupBy === 'partner') {
              let partner = this.getPartners.find((partner) => String(partner.id) === String(groupId));
              if (!partner) {
                return "?unknown?";
              }
              return partner.name;
            }

            if (this.appliedFilters.groupBy === 'sitegroup') {
              let sitegroup = this.getSitegroup.find((sitegroup) => String(sitegroup.id) === String(groupId));
              if (!sitegroup) {
                return "?unknown?";
              }
              return sitegroup.name;
            }

            let game = this.getGames.find((game) => String(game.id) === String(groupId));
            return game.name;
        },
        setOrderBy(key) {
            if (key === this.filters.orderBy) {
                this.filters.orderByDirection = this.filters.orderByDirection * -1;
            } else {
                this.filters.orderByDirection = 1;
            }
            this.filters.orderBy = key;
        },
        loadSummaryItems() {
            let appliedFilters = JSON.parse(JSON.stringify(this.filters));
            let loadingInstance = this.$veLoading({
                target: document.querySelector('#loading-container'),
                name: 'wave',
            });

            this.cancelRequest();

            this.startedAt = new Date();
            this.finishedAt = null;

            this.tables = [];

            let self = this;
            loadingInstance.show();

            let url = Vue.config.analyticsURL + '/summary';

            const { href } = this.$router.resolve({query: this.pageParams});
            window.history.replaceState({}, null, href);
            this.$http({
                url: url,
                params: this.pageParams,
                method: 'GET',
                cancelToken: new CancelToken(function executor(c) {
                    self.cancel = c;
                }),
            }).then(resp => {
                console.log("dashboard loaded: ", resp.data);

                this.appliedFilters = appliedFilters;

                let items = [];
                let betsData = resp.data['bets'];
                let total = {
                    id: 'total',
                    bets: 0,
                    wins: 0,
                    betsPerBet: 0,
                    winsPerBet: 0,
                    betsCount: 0,
                    playersCount: 0,
                    onlinePlayersCount: 0,
                    ggr: 0,
                    rtp: 0,
                    payout: 0,
                    perDevice: []
                }

                for (let index in betsData) {
                    let item = betsData[index];

                    if (!items[item['groupId']]) {
                        items[item['groupId']] = {
                            id: item['groupId'],
                            bets: 0,
                            wins: 0,
                            betsPerBet: 0,
                            winsPerBet: 0,
                            betsCount: 0,
                            playersCount: 0,
                            onlinePlayersCount: 0,
                            ggr: 0,
                            rtp: 0,
                            payout: 0,
                            perDevice: []
                        }
                    }

                    let currency = this.getCurrency(item['currency']);
                    let data = items[item['groupId']];

                    let record = {
                        id: item['deviceType'],
                        bets: parseFloat(item['debit']) * parseFloat(currency.rate),
                        wins: parseFloat(item['credit']) * parseFloat(currency.rate),
                        betsPerBet: parseFloat(item['debitPerBet']),
                        winsPerBet: parseFloat(item['creditPerBet']),
                        betsCount: parseInt(item['bets']),
                        playersCount: 0,
                        onlinePlayersCount: 0
                    }

                    if (!data.perDevice[item['deviceType']]) {
                        data.perDevice[item['deviceType']] = {
                            id: item['deviceType'],
                            bets: 0,
                            wins: 0,
                            betsPerBet: 0,
                            winsPerBet: 0,
                            betsCount: 0,
                            playersCount: 0,
                            onlinePlayersCount: 0
                        }
                    }
                    let perDeviceData = data.perDevice[item['deviceType']];

                    perDeviceData.bets += record.bets;
                    perDeviceData.wins += record.wins;
                    perDeviceData.betsPerBet += record.betsPerBet;
                    perDeviceData.winsPerBet += record.winsPerBet;
                    perDeviceData.betsCount += record.betsCount;


                    data.bets += record.bets;
                    data.wins += record.wins;
                    data.betsPerBet += record.betsPerBet;
                    data.winsPerBet += record.winsPerBet;
                    data.betsCount += record.betsCount;

                    data.rtp = data.winsPerBet / data.betsPerBet * 100;
                    data.ggr = data.bets - data.wins;
                    data.payout = data.wins / data.bets * 100;


                    total.bets += record.bets;
                    total.wins += record.wins;
                    total.betsPerBet += record.betsPerBet;
                    total.winsPerBet += record.winsPerBet;
                    total.betsCount += record.betsCount;

                    total.rtp = total.winsPerBet / total.betsPerBet * 100;
                    total.ggr = total.bets - total.wins;
                    total.payout = total.wins / total.bets * 100;

                    if (!total.perDevice[item['deviceType']]) {
                        total.perDevice[item['deviceType']] = {
                            id: item['deviceType'],
                            bets: 0,
                            wins: 0,
                            betsPerBet: 0,
                            winsPerBet: 0,
                            betsCount: 0,
                            playersCount: 0,
                            onlinePlayersCount: 0
                        }
                    }
                    let totalPerDevice = total.perDevice[item['deviceType']];
                    totalPerDevice.bets += record.bets;
                    totalPerDevice.wins += record.wins;
                    totalPerDevice.betsPerBet += record.betsPerBet;
                    totalPerDevice.winsPerBet += record.winsPerBet;
                    totalPerDevice.betsCount += record.betsCount;

                }

                items[0] = total;

                let playersData = resp.data['players'];
                for (let index in playersData) {
                    let record = playersData[index];
                    let groupId = record.groupId;
                    let deviceType = record.deviceType;
                    if (!items[groupId]) {
                        continue;
                    }

                    if (deviceType === 'total') {
                        items[groupId].playersCount = record.count;
                    } else {
                        if (!items[groupId].perDevice[deviceType]) {
                            continue;
                        }
                        items[groupId].perDevice[deviceType].playersCount = record.count;
                    }
                }

                playersData = resp.data['online'];
                for (let index in playersData) {
                    let record = playersData[index];
                    let groupId = record.groupId;
                    let deviceType = record.deviceType;
                    if (!items[groupId]) {
                        continue;
                    }

                    if (deviceType === 'total') {
                        items[groupId].onlinePlayersCount = record.count;
                    } else {
                        if (!items[groupId].perDevice[deviceType]) {
                            continue;
                        }
                        items[groupId].perDevice[deviceType].onlinePlayersCount = record.count;
                    }
                }

                let itemsArray = [];
                for (var id in items) {
                    itemsArray.push(items[id]);
                }


                this.summaryItems = itemsArray.sort(function (a, b) {
                    if (a.id === 'total') {
                        return -1;
                    }
                    if (a.id === b.id) {
                        return 0;
                    }
                    return a.id > b.id;
                })
                this.finishedAt = new Date();
            }).catch((err) => {
                console.log(err);
                this.summaryItems = [];
            }).finally(() => {
                loadingInstance.close();
                this.cancel = null;
            });
        },
        cancelRequest() {
            if (this.cancel) {
                this.cancel('this -> cancel previous request');
            }
        },
        applyFilters() {
            this.loadSummaryItems();
        },
        clearFilters() {
            for (const key of Object.getOwnPropertyNames(this.filters)) {
                if (typeof this.filters[key] === 'object') {
                    this.filters[key] = [];
                } else {
                    this.filters[key] = null;
                }
            }
            this.setFilterDefaults();
        },
        perDeviceTypeTableData(items) {
            let data = [];
            let self = this;
            ['Desktop', 'Mobile', 'Tablet', 'Other'].forEach(function (deviceType) {
                let item = items[deviceType.toLowerCase()];
                if (!item) {
                    data.push({
                        deviceType: deviceType,
                        playersCount: 0,
                        onlinePlayersCount: 0,
                        betsCount: 0,
                        bets: self.formatNumberToFixed(0),
                    });
                    return;
                }
                data.push({
                    deviceType: deviceType,
                    playersCount: self.formatNumberToFixed(item.playersCount, 0),
                    onlinePlayersCount: self.formatNumberToFixed(item.onlinePlayersCount, 0),
                    betsCount: self.formatNumberToFixed(item.betsCount, 0),
                    bets: self.formatNumberToFixed(item.bets),
                });
            });
            return data;
        },
        formatNumberToFixed: formatUtils.formatNumberToFixed,
        setFilterDefaults() {
            this.filters.fromDate = dateUtils.getTodayDate();
            this.filters.groupBy = 'sitegroup';
            this.filters.excludedUsers = 'exclude';
        },
        setFiltersFromRoute() {
            for (let paramName in this.$route.query) {
                let queryValues = this.$route.query[paramName];
                switch (paramName) {
                    case 'partnerIds':
                        if (!this.$route.query.partnerIds) {
                            break;
                        }

                        if (!Array.isArray(queryValues)) {
                            queryValues = [queryValues]
                        }
                        Vue.set(this.filters, 'partners', this.getPartners.filter(partner => queryValues.includes(partner.id.toString())));
                        break
                    case 'operatorIds':
                        if (!this.$route.query.operatorIds) {
                            break;
                        }

                        if (!Array.isArray(queryValues)) {
                            queryValues = [queryValues]
                        }
                        Vue.set(this.filters, 'operators', this.getOperators.filter(operator => queryValues.includes(operator.id.toString())));
                        break
                    default:
                        Vue.set(this.filters, paramName, this.$route.query[paramName]);
                        break;
                }
            }
        },
        tableDisplayName: formatUtils.getTableDisplayName,
    },
    mounted() {
        this.setFilterDefaults();
        this.setFiltersFromRoute();
        this.applyFilters();
    },
};
</script>
