<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog modal-md">
            <div class="modal-content">
                <form @submit="editUser" autocomplete="test-edit">
                    <input autocomplete="false" name="hidden" type="text" style="display:none;">
                    <div style="display: none;">
                        <input type="text" name="username" autocomplete="new-password">
                        <input type="email" name="email" autocomplete="new-password">
                        <input type="password" name="password" autocomplete="new-password">
                    </div>
                    <div style="overflow: hidden; height: 0;background: transparent;" data-description="dummyPanel for Chrome auto-fill issue">
                        <input type="text" name="username" style="height:0;background: transparent; color: transparent;border: none;" data-description="dummyUsername">
                        <input type="email" name="email" style="height:0;background: transparent; color: transparent;border: none;" data-description="dummyEmail">
                        <input type="password" name="password" style="height:0;background: transparent; color: transparent;border: none;" data-description="dummyPassword">
                    </div>
                    <div class="modal-header">
                        <h5 class="modal-title">{{ user.id ? 'Edit' : 'Create' }} user</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-sm-12">
                                <div class="form-group">
                                    <label>User name</label>
                                    <input type="text" name="username" style="display:none;">
                                    <input v-model="user.username"
                                           role="presentation"
                                           autocomplete="ignore-username"
                                           class="form-control"
                                           name="original-user"
                                           placeholder="Enter user name"
                                           required
                                           type="text"
                                    >
                                </div>
                                <div class="form-group">
                                    <label>E-mail</label>
                                    <input type="email" name="email" style="display:none;">
                                    <input v-model="user.email"
                                           id="email"
                                           role="presentation"
                                           autocomplete="off"
                                           class="form-control"
                                           name="original-mail"
                                           placeholder="Enter e-mail"
                                           required
                                           type="text"
                                    >
                                </div>
                                <div class="form-group">
                                    <label>Password</label>
                                    <div v-if="user.id">
                                        <input id="isChangePassword"
                                               role="presentation"
                                               v-model="isChangePassword"
                                               type="checkbox"
                                        >&nbsp;
                                        <label for="isChangePassword">Change password</label>
                                    </div>
                                    <input type="password" name="password" style="display:none;">
                                    <input v-model="user.password"
                                           id="pass"
                                           :disabled="!isChangePassword"
                                           :placeholder="!user.id ? 'Enter password' : ''"
                                           :required="user.id ? false : true"
                                           role="presentation"
                                           autocomplete="off"
                                           class="form-control"
                                           type="password"
                                    >
                                </div>
                                <div class="form-group">
                                    <label>Role</label>
                                    <multiselect v-model="currentRole"
                                                 :options="this.getUsersRoles"
                                                 placeholder="Select role"
                                    ></multiselect>
                                </div>
                                <div v-if="canSelectOperators" class="form-group">
                                    <label>Operators</label>
                                    <multiselect v-model="currentOperators"
                                                 :multiple="true"
                                                 :options="this.getOperators"
                                                 label="name"
                                                 placeholder="Select operators"
                                                 track-by="id"
                                    ></multiselect>
                                    <!--
                                                         :options="filter.options"
                                     :track-by="filter.trackBy"
                                     :label="filter.labelProp"
                                     -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>

                        <button v-if="isDebug"
                                class="close"
                                title="dev-only. clear fields"
                                type="button"
                                @click="clearData"
                        ><i class="fas fa-trash"></i></button>

                        <button v-if="isDebug"
                                class="close"
                                title="dev-only. fill with random data"
                                type="button"
                                @click="randomData"
                        ><i class="fas fa-dice"></i></button>

                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style scoped>
#modal-default {
    display: block;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import {generateRandomString} from '../helpers/makerUtils';
import {mapGetters} from 'vuex';
import * as roles from '../definitions/roles';
import * as env from '../definitions/env';
import * as formatUtils from '../helpers/formatUtils';

export default {
    props: ['item'],
    components: {
        Multiselect,
    },
    computed: {
        isDebug() {
            return process.env.NODE_ENV === env.DEVELOPMENT;
        },
        canSelectOperators() {
            return this.currentRole === roles.ROLE_OPERATOR;
        },
        ...mapGetters([
            'getUsersRoles',
            'getUserRole',
            'getOperators',
            'getDashboards',
        ]),
        getCurrentOperatorsIds() {
            return this.currentOperators.map(function(item) {
                return item.id;
            });
        },
    },
    methods: {
        randomData() {
            this.user.username = generateRandomString(10);
            this.user.email = generateRandomString(10) + '@example.com';
            this.user.password = 'qwerty123';
        },
        clearData() {
            this.user.username = '';
            this.user.email = '';
            this.user.password = '';
        },
        editUser(e) {
            e.preventDefault();

            if (!this.currentRole) {
                this.$toastr.e('Please select user role');
                return;
            }

            let url = null;
            let method = null;

            if (this.user.id) {
                method = 'PUT';
                url = '/users/' + this.user.id;

            } else {
                method = 'POST';
                url = '/users';
            }

            this.$http({
                url,
                data: {
                    username: this.user.username,
                    email: this.user.email,
                    password: this.user.password,
                    role: this.currentRole,
                    operators: this.getCurrentOperatorsIds,
                    isChangePassword: this.isChangePassword,
                },
                method: method,
            }).then(() => {
                this.$emit('close');
                this.$emit('finish');
            }).catch((err) => {
                console.error(err);
            });
        },
        tableDisplayName: formatUtils.getTableDisplayName,
        getNodeIdForOperator(operator) {
            return 'operator;' + operator.id;
        },
    },
    mounted() {
        this.$store.dispatch('openModal');

        if (this.user.id) {
            this.user.password = '';

            this.getUsersRoles.forEach(role => {
                if (role === this.user.role.id) {
                    this.currentRole = role;
                }
            });

            if (this.user.operators) {
                this.currentOperators = this.user.operators.map(function(item) {
                    return item;
                });
            }
        } else {
            this.isChangePassword = true;
        }

        document.getElementById('email').setAttribute('autocomplete', 'randomString');
        document.getElementById('pass').setAttribute('autocomplete', 'randomString');
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
    data() {
        return {
            user: {...this.item},
            currentRole: {},
            currentOperators: [],
            isChangePassword: false,
        };
    },
};
</script>
