<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-users-cog"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <div class="row col-lg-12">
                    <div class="col-sm-2">
                        <input class="btn btn-primary" type="button" value="Add new" @click="showEditUser">
                    </div>
                </div>


                <data-table-wrapper
                    ref="usersTable"
                    :filters="filters"
                    :columns="columns"
                    endpoint="/excluded-users"
                    api-service="analytics"
                    sort-column-default="id"
                    sort-order-default="asc"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    v-on:delete-request="this.showDeleteUser"
                ></data-table-wrapper>
            </div>

            <EditExcludedUser
                v-if="editUser"
                v-on:close="this.hideEditUser"
                v-on:finish="() => { this.onFinishEdit(); }"
            ></EditExcludedUser>

            <DeleteExcludedUser
                v-if="deleteUser"
                :item="deleteUser"
                v-on:close="this.hideDeleteUser"
                v-on:success="this.loadUsers"
            ></DeleteExcludedUser>
        </section>
    </div>
</template>

<script>
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from 'vuex';
import EditExcludedUser from "../components/EditExcludedUser";
import DeleteExcludedUser from "../components/DeleteExcludedUser";

export default {
    components: {
        DataTableWrapper,
        EditExcludedUser,
        DeleteExcludedUser
    },
    data() {
        return {
            filters: [],
            columns: [
                {
                    field: 'id',
                    title: 'ID',
                    sorting: true,
                },
                {
                    field: 'operatorId',
                    title: 'Operator',
                    callback: ({row}) => {
                        let operator = this.getOperator(row.operatorId.toString());
                        return operator ? operator.name : row.operatorId;
                    },
                    sorting: false,
                },
                {
                    field: 'uid',
                    title: 'UID',
                    sorting: false,
                },
                {
                    field: 'createdAt',
                    title: 'Created At',
                    sorting: false,
                },
            ],
            editUser: null,
            deleteUser: null,
        };
    },
    computed: {
        ...mapGetters([
            'getUsersRoles',
            'getCurrentUser',
            'getOperators',
            'getPartners',
            'getOperator'
        ]),
    },
    methods: {
        showEditUser(data) {
            if (data === null) {
                data = {};
            } else {
                // spread (workaround for clone, or main list will be edited realtime)
                data = {...data};
            }

            this.editUser = data;
        },
        loadUsers() {
            this.$refs.usersTable.loadData();
        },
        hideEditUser() {
            this.editUser = null;
        },
        onFinishEdit() {
            this.loadUsers();
        },
        showDeleteUser(data) {
            this.deleteUser = data;
        },
        hideDeleteUser() {
            this.deleteUser = null;
        },
    },
    mounted() {
    },
};
</script>
