<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">GGR</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-list"></i> GGR
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <data-table-wrapper
                    ref="dataTable"
                    :columns="columns"
                    :filters="filters"
                    :load-data-callback="loadDataCallback"
                    :filter-defaults="filterDefaults"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    endpoint="/ggr"
                    api-service="analytics"
                >
                    <template v-slot:filter-buttons>
                        <button class="btn btn-default float-right" @click="doExport">
                            <span v-if="!exportInProgress">
                                <i class="fa fa-cloud-download"></i> {{ $t('Export') }}
                            </span>
                            <span v-else>
                                <i class="fas fa-spinner fa-pulse"></i> {{ $t('Export') }}
                            </span>
                        </button>
                    </template>
                </data-table-wrapper>
            </div>

        </section>
    </div>
</template>

<script>
import * as formatUtils from '../helpers/formatUtils';
import * as dateUtils from '../helpers/dateUtils';
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from "vuex";

export default {
    components: {
        DataTableWrapper,
    },
    data() {
        let self = this;
        return {
            exportInProgress: false,
            currencyDate: 'now',
            ratesToEUR: {},
            loadDataCallback: (component, respData) => {
                component.items = respData;
                component.pagination.onPage = component.pagination.total = component.items.length
                component.pagination.page = 1;
                component.showEmpty = true;
            },
            filters: [
                {
                    field: 'fromDate',
                    placeholder: 'From Date',
                    label: 'From Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'toDate',
                    placeholder: 'To Date',
                    label: 'To Date',
                    type: 'date-picker',
                    shortcuts: dateUtils.getFilterShortcuts()
                },
                {
                    field: 'currencyRate',
                    placeholder: 'Select',
                    label: 'Currency Rate',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: false,
                    getValue: (rate) => { return rate.date },
                    getOptions: () => this.getCurrencyRates,
                    onSelect: (value) => { this.setCurrencyRate(value) },
                    trackBy: 'date'
                },
                {
                    field: 'partnerIds',
                    placeholder: 'Select partners',
                    label: 'Partners',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (partners) => { return partners ? partners.map(partner => partner.id) :[]},
                    getOptions: () => this.getPartners,
                    trackBy: 'id'
                },
                {
                    field: 'operatorIds',
                    placeholder: 'Select operators',
                    label: 'Operators',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (operators) => { return operators ? operators.map(operator => operator.id) : [] },
                    getOptions: () => this.getOperatorsOptions,
                    trackBy: 'id'
                },
                {
                    field: 'gameIds',
                    placeholder: 'Select games',
                    label: 'Games',
                    type: 'multiselect',
                    optionLabel: 'name',
                    multiple: true,
                    getValue: (games) => { return games ? games.map(game => game.id) : [] },
                    getOptions: () => this.getGames,
                    trackBy: 'id'
                },
            ],
            columns: [
                {
                    field: 'partnerId',
                    title: 'Partner',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getPartner(row.partnerId.toString()).name;
                    },
                },
                {
                    field: 'operatorId',
                    title: 'Operator',
                    sorting: false,
                    callback: ({row}) => {
                        return self.getOperator(row.operatorId.toString()).name;
                    },
                },
                {
                    field: 'currency',
                    title: 'Currency',
                    sorting: false,
                },
                {
                    field: 'debit',
                    title: 'Bets',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.debitSum)
                    },
                },
                {
                    field: 'credit',
                    title: 'Wins',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.creditSum)
                    },
                },
                {
                    field: 'ggr',
                    title: 'GGR',
                    sorting: false,
                    callback: ({row}) => {
                        return formatUtils.formatNumberToFixed(row.debitSum - row.creditSum)
                    },
                },
                {
                    field: 'rate',
                    title: 'Rate EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let rate = this.getRate(row.currency);
                        if (rate === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(rate)
                    },
                },
                {
                    field: 'debit_eur',
                    title: 'Bets EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let rate = this.getRate(row.currency);
                        if (rate === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(row.debitSum * rate)
                    },
                },
                {
                    field: 'credit_eur',
                    title: 'Wins EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let rate = this.getRate(row.currency);
                        if (rate === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed(row.creditSum * rate)
                    },
                },
                {
                    field: 'ggr_eur',
                    title: 'GGR EUR',
                    sorting: false,
                    callback: ({row}) => {
                        let rate = this.getRate(row.currency);
                        if (rate === undefined) {
                            return 'n/a';
                        }
                        return formatUtils.formatNumberToFixed((row.debitSum - row.creditSum) * rate)
                    },
                },

                {
                    field: 'betsCount',
                    title: 'Bets Count',
                    sorting: false,
                },
            ],
        };
    },
    computed: {
        ...mapGetters([
            'getGames',
            'getGame',
            'getPartners',
            'getPartner',
            'getOperators',
            'getOperator',
            'getCurrency',
            'isOperator',
        ]),
        getCurrencyRates() {
            let options = [];
            options.push({date: 'now', name: 'Current rate'});

            let now = new Date();
            let nowUTC = new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),  now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

            for (let i = 0; i < 12; i++) {
                nowUTC.setDate(1);
                nowUTC.setHours(-1);
                options.push({date: nowUTC.toISOString().split('T')[0], name: nowUTC.toLocaleDateString("en-US", {year: 'numeric', month: 'long'})});
            }
            return options;
        },
        getOperatorsOptions() {
            return this.getOperators.filter((operator) => {
                let selectedPartnerIds = this.$refs.dataTable.getFilterValue('partnerIds');
                if (selectedPartnerIds.length === 0) {
                    return true;
                }

                if (!operator.partnerId) {
                    return false;
                }

                return selectedPartnerIds.includes(operator.partnerId);
            });
        },
        filterDefaults() {
            return {
                fromDate: dateUtils.getTodayDate(),
                currencyRate: this.getCurrencyRates.find((rate) => rate.date === 'now')
            }
        },
    },
    methods: {
        setCurrencyRate(value) {
            let date = value.date;
            if (!date) {
                date = 'now';
            }
            if (date === 'now' || this.ratesToEUR[date]) {
                this.currencyDate = date;
                return;
            }

            let self = this;
            this.$http({
                url: '/currency-rates/' + date,
                method: 'GET',
            }).then(resp => {
                self.ratesToEUR[date] = resp.data
                self.currencyDate = date;
            }).catch((err) => {
                console.log(err);
            });
        },
        getRate(currency) {
            try {
                if (this.currencyDate === 'now') {
                    return this.getCurrency(currency).rate
                }
                if (!this.ratesToEUR[this.currencyDate]) {
                    return this.getCurrency(currency).rate
                }
                let currencyRate = this.ratesToEUR[this.currencyDate].find((item) => item.currency === currency);

                return currencyRate.rate ? currencyRate.rate : this.getCurrency(currency).rate;
            } catch (e) {
                return undefined
            }
        },
        doExport() {
            if (this.exportInProgress) {
                return;
            }
            this.exportInProgress = true;
            this.$exportService.doExport('ggr', this.$refs.dataTable.getPageQuery()).finally(() => {
                this.exportInProgress = false;
            });
        },
    },
    mounted() {
    },
};
</script>
