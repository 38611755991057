<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <form @submit="deleteCurrency">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm delete</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close-modal')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                Are you sure you want to delete currency?
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                Code: {{ currency.code }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                Name: {{ currency.name }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close-modal')">Cancel</button>
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
#modal-default {
    display: block;
}
</style>

<script>
export default {
    props: ['currency'],
    computed: {},
    methods: {
        deleteCurrency(e) {
            e.preventDefault();

            this.$http({
                url: '/currencies/' + this.currency.code,
                method: 'DELETE',
            }).then(() => {
                this.$emit('close-modal');
                this.$emit('update-items');
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
};
</script>
