<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-dice"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <data-table-wrapper
                    ref="gamesTable"
                    :filters="filters"
                    :columns="columns"
                    disable-delete-action="true"
                    endpoint="/games"
                    sort-column-default="id"
                    sort-order-default="ASC"
                    v-on:edit-request="this.showEditGame"
                ></data-table-wrapper>
            </div>

            <edit-game
                v-if="editGame"
                :game="editGame"
                v-on:close="this.hideEditGame"
                v-on:finish="() => { this.onFinishEdit(); }"
            ></edit-game>

        </section>
    </div>
</template>

<script>
import EditGame from '../components/EditGame';
import DataTableWrapper from '../components/DataTableWrapper';

export default {
    components: {
        EditGame,
        DataTableWrapper,
    },
    data() {
        return {
            filters: [],
            columns: [
                {
                    field: 'name',
                    title: 'Name',
                    sorting: true,
                },
                {
                    field: 'systemName',
                    title: 'System name',
                },
                {
                    field: 'isActive',
                    title: 'Is active',
                    callback: ({row}) => {
                        return row.isActive ? 'Yes' : 'No';
                    },
                },
            ],
            editGame: null,
        };
    },
    methods: {
        showEditGame(data) {
            data = {...data};

            this.editGame = data;
        },
        hideEditGame() {
            this.editGame = null;
        },
        onFinishEdit() {
            this.loadGames();
        },
        loadGames() {
            this.$refs.gamesTable.loadData();
        },
    },
    mounted() {
    },
};
</script>
