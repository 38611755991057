<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <form @submit="deletePartner">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm delete</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="row">
                            <div class="col-lg-12">
                                Are you sure you want to delete operator?
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-lg-12">
                                ID: {{ operator.id }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                Name: {{ operator.name }}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-12">
                                Partner: {{ operator.partnerName }}
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>
                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
#modal-default {
    display: block;
}
</style>

<script>
export default {
    props: ['operator'],
    computed: {},
    methods: {
        deletePartner(e) {
            e.preventDefault();

            this.$http({
                url: '/operators/' + this.operator.id,
                method: 'DELETE',
            }).then(() => {
                this.$emit('close');
                this.$emit('success');
            }).catch((err) => {
                let data = err.response.data[0];

                if (data.code === 'invalid-operation') {
                    this.$toastr.e(data.message);
                }
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
};
</script>
