<template>
    <div>
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-capitalize">{{ this.$route.name }}</h1>
                    </div>
                    <div class="col-sm-6">
                        <ol class="breadcrumb float-sm-right">
                            <li class="breadcrumb-item">
                                <router-link to="/">Home</router-link>
                            </li>
                            <li class="breadcrumb-item active text-capitalize"><i class="fas fa-users-cog"></i> {{ this.$route.name }}
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="content">
            <div class="container-fluid">
                <data-table-wrapper
                    ref="exportTable"
                    :filters="filters"
                    :columns="columns"
                    :on-data-load="onDataLoad"
                    endpoint="/export"
                    api-service="analytics"
                    disable-primary-column="true"
                    disable-edit-action="true"
                    disable-delete-action="true"
                    sort-column-default="createdAt"
                    sort-order-default="desc"
                >
                </data-table-wrapper>
            </div>
        </section>
    </div>
</template>

<script>
import DataTableWrapper from '../components/DataTableWrapper';
import {mapGetters} from 'vuex';

export default {
    components: {
        DataTableWrapper,
    },
    data() {
        return {
            onDataLoad: () => {
                /*let self = this;
                setTimeout(function () {
                    self.$refs.exportTable.loadData({silent:true});
                }, 5000)*/
            },
            filters: [],
            columns: [
                {
                    field: 'id',
                    title: 'ID'
                },
                {
                    field: 'createdAt',
                    title: 'Created',
                    isDate: true
                },
                {
                    field: 'count',
                    title: 'Progress',
                    callback: ({ row }) => {
                        return (
                            <b-progress max={parseInt(row.totalCount)} show-progress animated={this.isAnimatedProgress(row)} class="rounded position-relative">
                                <b-progress-bar value={parseInt(row.count)} variant={this.getProgressVariant(row)}>
                                    <span></span>
                                </b-progress-bar>
                                <small class="justify-content-center d-flex position-absolute w-100" style="top: 50%; color: white;">
                                    <strong>{ row.count } / {row.totalCount }</strong>
                                </small>
                            </b-progress>
                        );
                    },
                },
                {
                    field: 'filename',
                    title: 'Filename',
                    callback: ({ row }) => {
                        if (row.status !== 2) {
                            return (
                                <div>
                                    <span>{row.filename}</span>
                                </div>
                            )
                        }
                        return (
                            <div>
                                <span>{row.filename}</span>
                                <a href={row.link} target="_blank" class="ml-2 btn btn-success btn-xs"><i class="fa fa-download"></i> Download</a>
                            </div>
                        );
                    },
                },
            ],

            editUser: null,
            deleteUser: null,
        };
    },
    computed: {
        ...mapGetters([
        ]),
    },
    methods: {
        isAnimatedProgress(row) {
            return row.status === 0 || row.status === 1;
        },
        getProgressVariant(row) {
            if (row.status === 2) {
                return 'success';
            }
            if (row.status === 0 || row.status === 1) {
                return 'primary';
            }
            return 'warning';
        }
    },
    mounted() {
    },
};
</script>
