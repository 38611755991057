import Vue from 'vue';
import Vuex from 'vuex';
import Axios from 'axios';
import * as types from './types';
import {ROLE_ADMIN, ROLE_OPERATOR, ROLE_REPORTS} from '../definitions/roles';
import {DEVELOPMENT} from '../definitions/env';

Vue.use(Vuex);

function getInitialState() {
    return {
        status: null,
        inited: false,
        token: localStorage.getItem('token') || null,
        user: {
            username: localStorage.getItem('username') || null,
        },
        isModalOpened: false,
        usersRoles: [
            ROLE_ADMIN,
            ROLE_REPORTS,
            ROLE_OPERATOR,
        ],
        userRole: null,
        tables: [],
        partners: [],
        operators: [],
        sitegroup: [],
        reelsPresets: [],
        games: [],
        currentUser: null,
        currencies: [],
        showDebugTools: false,
        currentUserLoaded: false,
    };
}

function isDebugEnabled(state) {
    if (process.env.NODE_ENV === DEVELOPMENT) {
        return true;
    }

    if (state.currentUser && process.env.VUE_APP_DEBUG_USERNAME && state.currentUser.username === process.env.VUE_APP_DEBUG_USERNAME) {
        return true;
    }

    return false;
}

export default new Vuex.Store({
    state: getInitialState(),
    mutations: {
        [types.auth_request](state) {
            state.status = 'loading';
        },
        [types.init_complete](state) {
            state.inited = true;
        },
        [types.auth_success](state, {token, user}) {
            state.status = 'success';
            state.token = token;
            state.user.username = user.username;
        },
        [types.auth_error](state) {
            state.status = 'error';
        },
        [types.logout](state) {
            state.status = null;
            state.token = null;
        },
        [types.current_user_loaded](state, currentUser) {
            state.currentUser = currentUser;
            state.userRole = currentUser.role.id;
        },
        [types.TOGGLE_DEBUG_TOOLS](state) {
            state.showDebugTools = !state.showDebugTools;
        },
    },
    actions: {
        login({commit}, user) {
            return new Promise((resolve, reject) => {
                commit(types.auth_request);

                Axios({
                    url: '/token/get',
                    data: user,
                    method: 'POST',
                }).then(resp => {
                    const token = resp.data.token;
                    const refreshToken = resp.data.refreshToken;

                    localStorage.setItem('token', token);
                    localStorage.setItem('refreshToken', refreshToken);
                    localStorage.setItem('username', user.username);

                    let refererUrl = localStorage.getItem('referer');
                    window.location.href = refererUrl ?? '/';

                }).catch((err) => {
                    commit(types.auth_error);
                    localStorage.removeItem('token');
                    console.error(err);
                    reject(err);
                });
            });
        },
        logout() {
            this.replaceState(getInitialState());

            localStorage.removeItem('token');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('username');
            localStorage.removeItem('referer');

            delete Axios.defaults.headers.common['Authorization'];
        },
        openModal() {
            document.body.classList.add('modal-open');
            this.state.isModalOpened = true;
        },
        hideModal() {
            document.body.classList.remove('modal-open');
            this.state.isModalOpened = false;
        },
        loadApplicationData({commit, dispatch}) {
            if (this.state.token === null) {
                return;
            }

            return Promise.all([
                dispatch('loadCurrentUser'),
                dispatch('loadPartners'),
                dispatch('loadOperators'),
                dispatch('loadSitegroup'),
                dispatch('loadCurrencies'),
                dispatch('loadGames'),
            ]).then(() => commit(types.init_complete))
        },
        loadCurrentUser({commit}) {
            return Axios({
                url: '/users/me',
                method: 'GET',
            }).then(resp => {
                commit(types.current_user_loaded, resp.data);
                this.state.currentUserLoaded = true;
            }).catch((err) => {
                console.log(err);
            });
        },
        loadCurrencies() {
            return Axios({
                url: '/currencies/all',
                method: 'GET',
            }).then(resp => {
                this.state.currencies = resp.data;
            }).catch((err) => {
                console.log(err);
            });
        },
        loadPartners() {
            return Axios({
                url: '/partners/all',
                method: 'GET',
            }).then(resp => {
                this.state.partners = resp.data.sort((a, b) => a.name.localeCompare(b.name));
            }).catch((err) => {
                console.log(err);
            });
        },
        loadOperators() {
            return Axios({
                url: '/operators/all',
                method: 'GET',
            }).then(resp => {
                this.state.operators = resp.data.sort((a, b) => a.name.localeCompare(b.name));
            }).catch((err) => {
                console.log(err);
            });
        },
        loadSitegroup() {
            return Axios({
                url: '/sitegroup/all',
                method: 'GET',
            }).then(resp => {
                this.state.sitegroup = resp.data.sort((a, b) => a.name.localeCompare(b.name));
            }).catch((err) => {
                console.log(err);
            });
        },
        loadReelsPresets() {
            return Axios({
                url: '/reels-presets',
                method: 'GET',
            }).then(resp => {
                this.state.reelsPresets = resp.data;
            }).catch((err) => {
                console.log(err);
            });
        },
        loadGames() {
            return Axios({
                url: '/games/all',
                method: 'GET',
            }).then(resp => {
                this.state.games = resp.data.sort((a, b) => a.name.localeCompare(b.name));
            }).catch((err) => {
                console.log(err);
            });
        },
    },
    getters: {
        isModalOpened: state => state.isModalOpened,
        isLoggedIn: state => !!state.token,
        isInited: state => state.inited,
        authStatus: state => state.status,
        username: state => state.user.username,
        getUsername: state => state.user.username,
        getCurrentUser: state => state.currentUser,
        isAdmin: state => state.currentUser && state.currentUser.role.id === ROLE_ADMIN,
        isOperator: state => state.currentUser && state.currentUser.role.id === ROLE_OPERATOR,
        isDebugEnabled: state => isDebugEnabled(state),
        isShowDebugTools: state => state.showDebugTools,
        getPartners: state => state.partners,
        getSitegroup: state => state.sitegroup,
        getPartner: (state) => (id) => state.partners.find(partner => partner.id === id),
        getOperators: state => state.operators,
        getOperator: (state) => (id) => state.operators.find(operator => operator.id === id),
        getGames: state => state.games,
        getGame: (state) => (id) => state.games.find(game => game.id === id),
        getReelsPresets: state => state.reelsPresets,
        getUsersRoles: state => state.usersRoles,
        getUserRole: state => state.userRole,
        getCurrencies: state => state.currencies,
        getCurrency: (state) => (code) => state.currencies.find(currency => currency.code === code),
        getLangs: state => {
            if (isDebugEnabled(state)) {
                return [
                    'en',
                    'debug',
                ];
            }

            return [
                'en',
            ];
        },
        currentUserLoaded: state => state.currentUserLoaded,
    },
});
