<template>
    <div id="modal-default" aria-modal="true" class="modal fade show" role="dialog">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <form @submit="submit">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ this.isCreateMode ? 'Create' : 'Edit' }} partner</h5>
                        <button aria-label="Close" class="close" data-dismiss="modal" type="button"
                                @click="$emit('close')">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label>Name</label>
                            <input v-model="currentPartner.name"
                                   autocomplete="off"
                                   class="form-control"
                                   name="name"
                                   placeholder="Enter name"
                                   required
                                   type="text"
                            >
                        </div>
                        <div class="form-group">
                            <label>Business name</label>
                            <input v-model="currentPartner.businessName"
                                   autocomplete="off"
                                   class="form-control"
                                   name="business-name"
                                   placeholder="Enter business name"
                                   required
                                   type="text"
                            >
                        </div>
                        <div class="form-group">
                            <label>Contact email</label>
                            <input v-model="currentPartner.contactEmail"
                                   autocomplete="off"
                                   class="form-control"
                                   name="contact-email"
                                   placeholder="Enter contact email"
                                   required
                                   type="email"
                            >
                        </div>
                        <div class="form-group">
                            <label>Contact person</label>
                            <input v-model="currentPartner.contactPerson"
                                   autocomplete="off"
                                   class="form-control"
                                   name="contact-person"
                                   placeholder="Enter contact person"
                                   required
                                   type="text"
                            >
                        </div>
                        <div class="form-group">
                            <label>Address</label>
                            <input v-model="currentPartner.address"
                                   autocomplete="off"
                                   class="form-control"
                                   name="address"
                                   placeholder="Enter address"
                                   type="text"
                            >
                        </div>
                        <div class="form-group">
                            <label>Notes</label>
                            <input v-model="currentPartner.notes"
                                   autocomplete="off"
                                   class="form-control"
                                   name="notes"
                                   placeholder="Enter notes"
                                   type="text"
                            >
                        </div>


                    </div>
                    <div class="modal-footer justify-content-between">
                        <button class="btn btn-default" type="button" @click="$emit('close')">Cancel</button>

                        <button v-if="isDebug"
                                class="close"
                                title="dev-only. clear fields"
                                type="button"
                                @click="clearData"
                        ><i class="fas fa-trash"></i></button>

                        <button v-if="isDebug"
                                class="close"
                                title="dev-only. fill with random data"
                                type="button"
                                @click="randomData"
                        ><i class="fas fa-dice"></i></button>

                        <button class="btn btn-primary" type="submit">Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<style>
#modal-default {
    display: block;
}
</style>

<script>
import Multiselect from 'vue-multiselect';
import {generateRandomString} from '../helpers/makerUtils';

const MODE_CREATE = 'create';

export default {
    props: [
        'partner',
        'mode',
    ],
    components: {
        Multiselect,
    },
    computed: {
        isCreateMode() {
            return this.mode === MODE_CREATE;
        },
        isDebug() {
            return process.env.NODE_ENV === 'development';
        },
    },
    methods: {
        randomData() {
            this.currentPartner.name = generateRandomString(10);
            this.currentPartner.businessName = generateRandomString(10);
            this.currentPartner.contactEmail = generateRandomString(10) + '@example.com';
            this.currentPartner.contactPerson = generateRandomString(10);
            this.currentPartner.address = generateRandomString(10);
            this.currentPartner.notes = generateRandomString(10);
        },
        clearData() {
            this.currentPartner.name = '';
            this.currentPartner.businessName = '';
            this.currentPartner.contactEmail = '';
            this.currentPartner.contactPerson = '';
            this.currentPartner.address = '';
            this.currentPartner.notes = '';
        },
        submit(e) {
            e.preventDefault();

            let url = null;
            let method = null;

            if (this.isCreateMode) {
                method = 'POST';
                url = '/partners';
            } else {
                method = 'PUT';
                url = '/partners/' + this.currentPartner.id;
            }

            this.$http({
                url: url,
                data: {
                    id: parseInt(this.currentPartner.id),
                    name: this.currentPartner.name,
                    businessName: this.currentPartner.businessName,
                    contactPerson: this.currentPartner.contactPerson,
                    contactEmail: this.currentPartner.contactEmail,
                    address: this.currentPartner.address ?? '',
                    notes: this.currentPartner.notes ?? '',
                },
                method: method,
            }).then(() => {
                this.$emit('finish');
                this.$emit('close');
                this.$store.dispatch('loadPartners');
            }).catch((err) => {
                console.error(err);
            });
        },
    },
    mounted() {
        this.$store.dispatch('openModal');
    },
    beforeDestroy() {
        this.$store.dispatch('hideModal');
    },
    data() {
        return {
            currentPartner: {...this.partner},
        };
    },
};

</script>
