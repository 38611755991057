<template>
    <div>
        <section class="content pt-2 pb-2">
            <div class="container-fluid">
                <div class="row" id="loading-container">
                    <div class="col-md-3 pr-0">
                        <div class="card mb-0">
                            <div class="card-header p-3 bg-light">
                                Translation IDs
                            </div>
                            <div style="height: calc(100vh - 130px); overflow-y: auto;">
                                <nav class="" style="font-size: 14px">
                                    <ul class="nav nav-pills nav-sidebar nav-child-indent flex-column"
                                        data-accordion="false" data-widget="treeview" role="menu">
                                        <li v-for="game in this.getGroups" :key="game.id" class="nav-item nav-item-light"
                                            :class="{'menu-open': isGroupOpened(game.id)}">
                                            <a href="#" class="nav-link" @click.prevent="toggleGroup(game.id)">
                                                <i class="fas fa-angle-right"></i>
                                                <span v-if="selectedGroup !== game.id"> {{ game.name }}</span>
                                                <span v-else><strong> {{ game.name }}</strong></span>

                                                <div data-toggle="buttons" class="btn-group btn-group-toggle" style="float:right">
                                                    <button :id="'add-group-btn-' + game.id"
                                                            @click.stop
                                                            class="ml-2 btn btn-outline-primary btn-xs btn-control"
                                                    >
                                                        <i class="fa fa-plus"></i>
                                                    </button>
                                                    <b-popover :ref="'addTermPopover' +  game.id"
                                                               boundary="window"
                                                               placement="right"
                                                               :target="'add-group-btn-' + game.id"
                                                               title="Create translation ID"
                                                               @show="closePopovers()"
                                                    >
                                                        <form @submit.prevent="addTerm($event.target.elements.name.value, game.id)" class="">
                                                            <div class="form-group">
                                                                <input name="name" type="text" class="form-control form-control-sm"
                                                                       value="">
                                                                <div class="invalid-feedback"></div>
                                                            </div>
                                                            <div class="d-flex justify-content-end m-0 form-group">
                                                                <button type="button" @click="closePopovers"
                                                                        class="btn btn-light btn-sm">Cancel
                                                                </button>
                                                                <button type="submit" class="ml-2 btn btn-success btn-sm">Create</button>
                                                            </div>
                                                        </form>
                                                    </b-popover>
                                                </div>

                                            </a>
                                            <ul class="nav nav-sidebar nav-treeview">
                                                <ul-node v-for="term in getGameTermsTree(game.id)"
                                                         :key="term.id"
                                                         :group-id="game.id"
                                                         :selected-group-id="selectedGroup"
                                                         :selected-id="selectedSubGroup"
                                                         :node="term"
                                                         @toggle="onNodeToggle"
                                                         @remove="onTermRemove"
                                                ></ul-node>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-9">
                        <div class="card mb-0">
                            <div class="card-header p-3 bg-light">
                                Translations
                                <strong v-if="this.selectedGroup !== null">{{ this.selectedGroup === 0 ? 'All Games' : getGame(this.selectedGroup).name }}</strong>

                                <button :disabled="deployInProgress" @click="deploy" title="Deploy" class="ml-2 btn btn-outline-danger btn-xs btn-control float-right">
                                    <i v-if="deployInProgress === false" class="fa fa-share-square"></i>
                                    <i v-else class="fa fa-spin fa-spinner"></i>
                                </button>
                                <button @click="showStatModal" title="Settings" class="ml-2 btn btn-outline-primary btn-xs btn-control float-right"><i class="fa fa-cog"></i></button>
                                <button v-if="selectedGroup !== null" title="Export" @click="$refs.exportModal.show(selectedGroup)" class="ml-2 btn btn-outline-primary btn-xs btn-control float-right"><i class="fa fa-upload"></i></button>
                                <button v-if="selectedGroup !== null" title="Import" @click="$refs.importModal.show(selectedGroup)" class="ml-2 btn btn-outline-primary btn-xs btn-control float-right"><i class="fa fa-download"></i></button>
                            </div>
                            <div v-if="this.selectedGroup !== null"
                                 style="height: calc(100vh - 130px); overflow-y: auto;">
                                <div v-for="term in getTerms(selectedGroup, selectedSubGroup)"
                                     :key="term.id">
                                    <div class="card-header">{{ term.key }}</div>
                                    <div class="p-3">
                                        <div v-for="language in languages" :key="language.id"
                                             class="d-flex align-items-center form-group mb-1" style="position: relative">
                                            <label class="m-0 form-label" style="width: 30px;">{{
                                                    language.id
                                                }}</label>
                                            <div class="flex-grow-1">
                                                <input @change="updateTranslation(term, language, $event)" name="text"
                                                       type="text" class="form-control form-control-sm"
                                                       :value="getTransText(term, language)">
                                            </div>
                                            <span v-if="isUpdateTranslationInProgress(term.id, language.id)" style="position: absolute;right: 10px;">
                                                <i class="fa fa-spinner fa-spin" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <StatsModal ref="statsModal"
                                :disabled-codes="['en']"
                                :languages="this.languages"
                                :hidden-codes="[]"
                                @languageRemoved="languageRemoved($event)"
                                @languageChanged="languageChanged($event)"
                                @languageCreated="languageCreated($event)"
                    ></StatsModal>
                    <ExportModal ref="exportModal"
                                 :languages="this.languages"
                    ></ExportModal>
                    <ImportModal ref="importModal"
                                 :languages="this.languages"
                                 @onComplete="loadData"
                    ></ImportModal>
                </div>
            </div>
        </section>
    </div>
</template>

<style>
.nav-link.selected {
    font-weight: bold;
}

.nav-sidebar .menu-is-opening > .nav-link i.fa-angle-right, .nav-sidebar .menu-open > .nav-link i.fa-angle-right {
    transform: rotate(90deg);
}

.btn-control.btn-xs {
    width: 25px;
}

.nav-item-light {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
    margin: 0 !important;
}

.nav-item-light:last-child {
    border-bottom: none !important;
}

.nav-item-light a {
    padding: 4px 12px !important;
    /*color: #1f2d3d !important;*/
    margin-bottom: 0 !important;
}
</style>

<script>


import {mapGetters} from "vuex";
import UlNode from "../components/UlNode.vue";
import Vue from "vue";
import StatsModal from "../components/Localization/StatsModal";
import ExportModal from "../components/Localization/ExportModal";
import ImportModal from "../components/Localization/ImportModal";

export default {
    data() {
        return {
            groups: [],
            termsByGame: [],
            translationsByIndex: [],
            languages: [],
            openedGroups: [],
            selectedGroup: null,
            selectedSubGroup: null,
            deployInProgress: false,
            inProgressTranslationUpdate: []
        }
    },
    components: {
        UlNode,
        StatsModal,
        ExportModal,
        ImportModal
    },
    computed: {
        ...mapGetters([
            'getGames',
            'getGame'
        ]),
        getGroups: function () {
            let groups = this.getGames.map((game) => {
                return {
                    id: game.id,
                    name: game.name
                }
            });
            groups.unshift({
                id: 0,
                name: 'All Games'
            });
            return groups;
        }
    },
    methods: {
        showStatModal() {
            let group = this.getGroups.filter((group) => group.id === this.selectedGroup)[0] ?? null;
            this.$refs.statsModal.show(this.getGameTerms(this.selectedGroup), this.getGameTranslations(this.selectedGroup), group)
        },
        getGameTerms(groupId) {
            return this.termsByGame[groupId] ?? [];
        },
        getGameTranslations(groupId) {
            let termIds = this.getGameTerms(groupId).map((term) => term.id);
            let translations = [];
            for (let index in this.translationsByIndex) {
                let trans = this.translationsByIndex[index];
                if (termIds.indexOf(trans.termId) >= 0) {
                    translations.push(trans);
                }
            }
            return translations;
        },
        onTermRemove(node, groupId) {
            let term = this.termsByGame[groupId].filter((term) => term.key === node.id)[0];
            if (!term) {
                return;
            }

            this
                .$http({
                    url: '/localization/terms/' + term.id,
                    method: 'DELETE',
                })
                .then(() => {
                    Vue.set(this.termsByGame, groupId, this.termsByGame[groupId].filter((el) => el.id !== term.id));
                    this.closePopovers();
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        onNodeToggle(node, opened, groupId) {
            if (opened) {
                if (groupId !== this.selectedGroup) {
                    this.selectGroup(groupId);
                }
                this.selectedSubGroup = node.id;

            } else {
                this.selectedSubGroup = null;
            }
        },
        buildTree(array) {
            return  array.reduce((tree, key) => {
                key.split('.').reduce((obj, title) => obj[title] = obj[title] || {}, tree);
                return tree;
            }, {});
        },
        convertTree(tree, prevKey) {
            return Object.keys(tree).map(title => {
                let key = prevKey ? prevKey + '.' + title : title;
                let obj = {
                    title: title,
                    id: key
                };
                let children = this.convertTree(tree[title], key);
                if (children.length) obj.children = children;
                return obj;
            });
        },
        languageChanged(lang) {
            let changedLang = this.languages.filter((el) => el.id === lang.id)[0];
            if (!changedLang) {
                return;
            }
            changedLang.id = lang.id;
            changedLang.name = lang.name;
            changedLang.enabled = lang.enabled;
        },
        languageCreated(lang) {
            this.languages.push(lang);
        },
        languageRemoved(langId) {
            this.languages = this.languages.filter((el) => el.id !== langId);
        },
        deploy() {
            if (this.deployInProgress) {
                return;
            }
            this.deployInProgress = true;
            this.$http({
                url: '/localization/translations/deploy',
                method: 'POST',
            })
                .then(() => {
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.deployInProgress = false;
                })
        },
        isUpdateTranslationInProgress(termId, languageId) {
            return this.inProgressTranslationUpdate.indexOf(termId + '_' + languageId) >= 0;
        },
        updateTranslation(term, language, $event) {
            let text = $event.target.value;
            if (text === this.getTransText(term, language)) {
                return;
            }

            let trans = this.translationsByIndex[term.id + '_' + language.id];
            if (trans) {
                trans.text = text;
            } else {
                this.translationsByIndex[term.id + '_' + language.id] = {
                    termId: term.id,
                    languageId: language.id,
                    text: text
                }
            }
            this.inProgressTranslationUpdate.push(term.id + '_' + language.id);
            this.$http({
                url: '/localization/translations',
                method: 'POST',
                data: {
                    termId: term.id,
                    languageId: language.id,
                    text: text
                }
            })
                .then(resp => {
                    let trans = resp.data;
                    let existedTrans = this.translationsByIndex[trans.termId + '_' + trans.languageId]
                    if (!existedTrans) {
                        existedTrans = trans;
                        this.translationsByIndex[trans.termId + '_' + trans.languageId] = existedTrans;
                    }
                    existedTrans.text = text;
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    this.inProgressTranslationUpdate = this.inProgressTranslationUpdate.filter((item) => item !== term.id + '_' + language.id);
                })
        },
        closePopovers() {
            this.$root.$emit('bv::hide::popover');
        },
        addTerm(key, gameId) {
            if (!key) {
                return;
            }

            this
                .$http({
                    url: '/localization/terms',
                    method: 'POST',
                    data: {
                        key: key,
                        gameId: gameId
                    }
                })
                .then(resp => {
                    this.termsByGame[resp.data.gameId].push(resp.data);
                    this.$root.$emit('bv::hide::popover');
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        getTransText(term, lang) {
            let trans = this.translationsByIndex[term.id + '_' + lang.id];
            if (trans) {
                return trans.text
            }
            return '';
        },
        isGroupOpened(groupId) {
            return this.openedGroups.indexOf(groupId) >= 0;
        },
        selectGroup(groupId) {
            this.selectedGroup = groupId;
            this.selectedSubGroup = null;
        },
        toggleGroup(groupId) {
            if (!this.isGroupOpened(groupId)) {
                this.selectGroup(groupId)
                this.openedGroups.push(groupId);
            } else {
                this.selectedGroup = null;
                this.openedGroups = this.openedGroups.filter(item => item !== groupId);
            }
        },
        getTerms(gameId, subGroupId) {
            let gameTerms = this.termsByGame[gameId] ?? [];
            if (!subGroupId) {
                return gameTerms;
            }
            return gameTerms.filter((term) => {
                return term.key.indexOf(subGroupId) >= 0;
            });
        },
        getGameTermsTree(gameId) {
            let terms = this.termsByGame[gameId] ?? [];
            let tree = this.buildTree(terms.map((term) => term.key));
            return this.convertTree(tree);
        },
        loadData() {
            let loadingInstance = this.$veLoading({
                target: document.querySelector("#loading-container"),
                name: "wave",
            });
            loadingInstance.show();

            Promise.all([
                this
                    .$http({
                        url: '/localization/terms/all',
                        method: 'GET',
                    })
                    .then(resp => {
                        this.termsByGame = [];
                        resp.data.forEach((item) => {
                            if (!this.termsByGame[item.gameId]) {
                                this.termsByGame[item.gameId] = [];
                            }
                            this.termsByGame[item.gameId].push(item);
                        })
                    }),
                this
                    .$http({
                        url: '/localization/translations/all',
                        method: 'GET',
                    })
                    .then(resp => {
                        this.translationsByIndex = [];
                        resp.data.forEach((item) => {
                            this.translationsByIndex[item.termId + '_' + item.languageId] = item;
                        })
                    }),
                this
                    .$http({
                        url: '/localization/languages/all',
                        method: 'GET',
                    })
                    .then(resp => {
                        this.languages = resp.data;
                    }),
            ])
                .catch((err) => {
                    console.log(err);
                })
                .finally(() => {
                    loadingInstance.close();
                });

        }
    },
    mounted() {
        this.loadData();
    }
}
</script>
